import { reader } from '../helpers/action';
import { getHistoryChangeLogs } from '../api-client/changelog';

/* eslint-disable-next-line import/prefer-default-export */
export const changelogFetcher = (label, entityType) => {
  const title = `${label} changelog`;
  return reader(
    title,
    async (id, url) => {
      const { data } = await getHistoryChangeLogs({ entityType, id, url });
      return data;
    },
    { ignore: { result: [], status: [404] } },
  );
};
