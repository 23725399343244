import hashFunc from 'fnv1a';
import {
  get, isEmpty, keyBy, set,
} from 'lodash';

import { getRedirectUrl } from 'lib-frontend-shared/src/helpers/auth';

import auth0Client from '../auth0client';
import * as apiClient from '../api-client';
import {
  getStates, resetStore, updateAuth,
} from '../store';
import { isLogoutRequested, logout, clearLogin } from '../logout';
import { push, replace } from './route';
import * as toastActions from './toast';
import config from '../config';
import { appendImages, appendLinks, appendStyling } from '../internal-login-components';
import { setCookie } from '../cookie';
import { listen } from '../broadcastChannel';

const promisify = (callbackFunction) => (...args) => new Promise((resolve, reject) => {
  callbackFunction(...args, (err, result) => {
    if (err) {
      reject(err);
      return;
    }
    resolve(result);
  });
});

const auth0ParseHash = promisify(auth0Client.parseHash.bind(auth0Client));
const auth0CheckSession = promisify(auth0Client.checkSession.bind(auth0Client));

const getTenantInfo = (accessByTenantId = {}, tenantId) => {
  const tenantData = accessByTenantId?.[tenantId] || {};
  if (isEmpty(tenantData)) return {};
  const {
    role, merchants, locations, countries,
  } = tenantData;

  if (merchants) merchants.sort();

  const canAccessAllCountries = !countries;
  const canAccessAllLocations = !locations;
  const canAccessAllMerchants = !merchants;

  return {
    // properties for currently logged-in tenant
    tenantId,
    role,
    merchants,
    canAccessAllCountries,
    canAccessAllLocations,
    canAccessAllMerchants,
    locations,
    countries,
  };
};

const saveLastSelectedTenantId = ({
  tenantId,
  connector,
  carriyoUserId,
}) => {
  set(window, `sessionStorage.userPref-${connector}-${hashFunc(carriyoUserId)}-selectedTenantId`, tenantId);
};

const fetchLastSelectedTenantId = ({
  connector,
  carriyoUserId,
  accessByTenantId,
}) => {
  let tenantId = get(window, `sessionStorage.userPref-${connector}-${hashFunc(carriyoUserId)}-selectedTenantId`);
  if (!tenantId || !accessByTenantId[tenantId]) {
    const tenantIds = Object.keys(accessByTenantId || {});
    if (tenantIds.length === 1) {
      // pick first one alphabetically
      // eslint-disable-next-line prefer-destructuring
      tenantId = tenantIds.sort()[0];
      saveLastSelectedTenantId({ tenantId, connector, carriyoUserId });
    }
  }
  return tenantId ? getTenantInfo(accessByTenantId, tenantId) : {};
};

const handleTokens = async (param) => {
  const {
    accessToken = '',
    expiresIn = 0,
    idTokenPayload = {},
    idTokenPayload: {
      sub,
      email,
      // name: authName, // is this useful now that we have name in dynamodb
    } = {},
  } = param;
  const {
    connector = 'global',
    userId: carriyoUserId,
    name,
    picture,
    accessByTenantId,
    accessByAccountId,
    alertCategoryByTenantId,
  } = idTokenPayload[`${window.location.origin}/app_metadata`] || {};

  localStorage.setItem('idTokenPayload', JSON.stringify(idTokenPayload));

  // save accessToken to auth state here as middleware endpoint using this in authorization
  let authMeta = { accessToken, carriyoUserId };
  updateAuth(authMeta);

  const assignedTenants = Object.keys(accessByTenantId);
  if (!assignedTenants.length) {
    updateAuth({ authorizing: false });
    return {};
  }
  const hasAnyAccess = assignedTenants.includes('_ANY');
  const userTenants = keyBy(
    // FIXME: Re-evaluate this call as this should be 1. non-blocking 2. having an API key
    await apiClient.getUserTenants(hasAnyAccess ? undefined : assignedTenants[0]),
    'value',
  );
  const accessibleTenants = hasAnyAccess ? Object.keys(userTenants) : assignedTenants;
  const updatedAccessByTenantIds = accessibleTenants.reduce((acc, tenantId) => ({
    ...acc,
    [tenantId]: {
      name: userTenants[tenantId]?.name,
      // eslint-disable-next-line no-underscore-dangle
      ...(accessByTenantId[tenantId] || accessByTenantId._ANY),
    },
  }), {});
  const tenantInfo = fetchLastSelectedTenantId({
    connector,
    carriyoUserId,
    accessByTenantId: (
      assignedTenants.length > 1 || hasAnyAccess
    ) ? {} : updatedAccessByTenantIds,
  });
  authMeta = {
    ...authMeta,
    expiresAt: Date.now() + expiresIn * 1000,
    name,
    email,
    picture,
    accessByAccountId,
    accessByTenantId: updatedAccessByTenantIds,
    // eslint-disable-next-line no-underscore-dangle
    anyTenantAccess: accessByTenantId._ANY,
    alertCategoryByTenantId,
    connector,
    auth0UserId: connector === 'global' ? sub : undefined,
  };

  updateAuth({ authorizing: false, ...authMeta, ...tenantInfo });
  localStorage.setItem('auth', JSON.stringify(authMeta));
  localStorage.setItem('lastSessionCheck', Date.now());
  return { ...authMeta, ...tenantInfo };
};

export const authorize = async () => {
  // 0. Check if a logout request was present
  if (isLogoutRequested()) {
    clearLogin();
  }

  const { pathname, origin } = window.location;
  let { search } = window.location;
  const params = new URLSearchParams(search);

  // 1. reset if requested
  if (params.has('refresh_login')) {
    const url = new URL(window.location.href);
    clearLogin();
    url.searchParams.delete('refresh_login');
    window.location.href = url.href;
  }

  // 2. check state
  const state = getStates();
  if (state.auth.accessToken) return;

  // 3. check local storage
  const authMetaFromLocalStorage = JSON.parse(localStorage.getItem('auth'));
  if (
    authMetaFromLocalStorage
    && Date.now() < authMetaFromLocalStorage.expiresAt
  ) {
    const regex = /\/tenants\/([^\\/]+)(\/|$)/;
    const tenantFromURL = pathname.match(regex)?.[1] || null;
    const {
      accessByTenantId,
      carriyoUserId,
      connector,
    } = authMetaFromLocalStorage;

    if (tenantFromURL) {
      saveLastSelectedTenantId({
        tenantId: tenantFromURL,
        connector,
        carriyoUserId,
      });
    }
    const tenantInfo = fetchLastSelectedTenantId({
      connector,
      carriyoUserId,
      accessByTenantId,
    });
    updateAuth({
      authorizing: false,
      ...authMetaFromLocalStorage,
      ...tenantInfo,
    });
    return;
  }

  updateAuth({ authorizing: true });

  // 4. check for internal login url
  // obscure the login url path as it only for internal use
  if (window.location.search === '?8ba0d1b8-f53a-47ce-b305-62a6054e03f1') {
    appendLinks();
    appendImages();
    appendStyling();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.auth0.com/js/lock/11.30/lock.min.js';
    script.onload = () => {
      const languageDictionary = {
        title: 'Sign in',
        loginSubmitLabel: 'Sign In',
        forgotPasswordSubmitLabel: 'Confirm',
        forgotPasswordAction: 'Forgot your password?',
        emailInputPlaceholder: 'Email',
        passwordInputPlaceholder: 'Password',
        error: {
          login: {
            'lock.invalid_email_password': 'Wrong email or password. Please try again.',
          },
        },
      };
      const lock = new window.Auth0Lock(
        config.auth0.clientId,
        config.auth0.authDomain,
        {
          auth: {
            redirectUrl: origin,
            params: {
              scope: 'openid profile email user_metadata app_metadata',
            },
            audience: config.backendBaseUrl,
            responseType: 'token id_token',
          },
          languageDictionary,
          allowedConnections: ['Username-Password-Authentication'],
          allowShowPassword: true,
        },
      );
      lock.show();
    };
    document.head.appendChild(script);
    return;
  }

  const urlWithForceResetRemoved = new URL(window.location.href);
  urlWithForceResetRemoved.searchParams.delete('refresh_login');
  const redirectUri = getRedirectUrl(urlWithForceResetRemoved.href).href;

  try {
    // 5. check url
    let parsedData;
    let authError;
    // Auth0 login
    if (
      window.location.hash.includes('access_token=')
      || window.location.hash.includes('error=')
    ) {
      try {
        parsedData = (await auth0ParseHash({
          hash: window.location.hash,
        })) || {};
      } catch (err) {
        authError = err;
      }
    }

    // Carriyo SSO login
    if (params.get('access_token')) {
      parsedData = {
        accessToken: decodeURIComponent(params.get('access_token')),
        expiresIn: parseInt(params.get('expires_in'), 10) || 0,
        idTokenPayload: JSON.parse(
          window.atob(
            decodeURIComponent(params.get('id_token')),
          ),
        ),
      };
      params.delete('access_token');
      params.delete('expires_in');
      params.delete('id_token');
      search = params.toString();
      if (search) search = `?${search}`;
    }
    if (params.get('auth_error')) {
      authError = JSON.parse(decodeURIComponent(params.get('auth_error')));
    }

    if (authError) {
      console.error(authError);
      const { errorDescription = '' } = authError;
      if (errorDescription.startsWith('User does not exist')) {
        toastActions.error(`Access denied. Your username has not been created with Carriyo yet. 
        Please ask your organization's Carriyo admin to create user first and then try to login again.`, Infinity);
      } else if (errorDescription.includes('`state` does not match.')) {
        // expired/invalid state. ask customer to login again
        auth0Client.authorize({ redirectUri });
        return;
      } else {
        toastActions.error('Unexpected error during login. Please inform carriyo support.', Infinity);
      }
      return;
    }

    if (parsedData) {
      const { expiresAt } = await handleTokens(parsedData);

      setCookie({
        expiresAt,
        lastLogin: Date.now(),
        lastLogout: undefined,
      });

      replace(`${pathname}${search}`);

      return;
    }

    // 6. redirects customer to auth0 for login
    auth0Client.authorize({ redirectUri });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const getReturnPath = (tenant, url) => {
  const isRoot = url.pathname === '/';
  const returnTo = url.searchParams.get('return_to');
  url.searchParams.delete('return_to');
  const returnPath = isRoot && returnTo?.startsWith('/tenants/')
    ? returnTo
    : `/tenants/${tenant}${
      isRoot && returnTo ? returnTo : `${url.pathname}${url.search}`
    }`;
  return returnPath.replace(/\/$/, '').replace(/\/\?/, '?');
};

export const setTenantId = (tenantId, avoidRedirection = false) => {
  // preserve path/query when redirecting from tenant-agnostic to tenant prefixed routes
  const unprefixedUrl = new URL(window.location.href);
  unprefixedUrl.pathname = unprefixedUrl.pathname.replace(/^\/tenants\/([^/]+\/?)?/, '/');

  // on tenant switch,
  // - redirect from internal pages (eg: edit/create) to the root/listing page
  // - remove URL params as they may not be relevant
  const redirectedUrl = new URL(unprefixedUrl.href);
  redirectedUrl.pathname = `/${redirectedUrl.pathname.split('/').at(1) || ''}`;
  [...redirectedUrl.searchParams.keys()].forEach((key) => {
    if (key !== 'return_to') {
      redirectedUrl.searchParams.delete(key);
    }
  });

  const composedPath = getReturnPath(
    tenantId,
    avoidRedirection ? unprefixedUrl : redirectedUrl,
  );

  const {
    auth: {
      authorizing,
      ...authMeta
    } = {},
  } = getStates();

  const newAuthMeta = { ...authMeta, ...getTenantInfo(authMeta?.accessByTenantId, tenantId) };
  resetStore(newAuthMeta);
  saveLastSelectedTenantId({
    tenantId,
    connector: authMeta.connector,
    carriyoUserId: authMeta.carriyoUserId,
  });
  push(composedPath, false);
};

let checkSessionTimer;
const fifteenMinInMs = 15 * 60 * 1000;
export const pollEnterpriseSSOSessionCheck = () => {
  const {
    auth: {
      authorizing,
      auth0UserId,
    },
    global: {
      tenantSettings: {
        enterpriseSsoEnabled,
      },
    },
  } = getStates();
  // console.log('enterpriseSsoEnabled', !!enterpriseSsoEnabled);
  // console.log('poll sso session?', !authorizing && !auth0UserId && enterpriseSsoEnabled);
  if (authorizing || auth0UserId || !enterpriseSsoEnabled) return;

  const checkSession = async () => {
    // check session. If error log out
    try {
      await auth0CheckSession({});
      localStorage.setItem('lastSessionCheck', Date.now());
    } catch (err) {
      logout();
    }
  };

  const resetTimer = () => {
    clearInterval(checkSessionTimer);
    if (document.visibilityState !== 'hidden') {
      // if user logged out of another tab, then log them out now.
      if (!localStorage.getItem('auth')) {
        logout();
        return;
      }
      checkSessionTimer = setInterval(checkSession, fifteenMinInMs);
      const lastCheck = parseInt(localStorage.getItem('lastSessionCheck'), 10) || Date.now();
      if ((Date.now() - lastCheck) > fifteenMinInMs) {
        checkSession();
      }
    }
  };

  // start timer if tab is open
  resetTimer();
  // start timer on tab focus switch
  document.addEventListener('visibilitychange', resetTimer);

  // eslint-disable-next-line consistent-return
  return () => document.removeEventListener('visibilitychange', resetTimer);
};


// --- Events ---

// real-time logout (Chrome)
listen('logoutRequest', () => logout(false));

// delayed logout (Safari)
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible' && isLogoutRequested()) {
    authorize();
  }
});
