/* eslint-disable react/no-unstable-nested-components */
// -- react, router -- //
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';

// -- custom --
import { identity, isEmpty } from 'lodash';
import { getRedirectUrl } from 'lib-frontend-shared/src/helpers/auth';
import { useGlobalStates } from '../../store';
import * as routeActions from '../../actions/route';
import { getReturnPath, setTenantId } from '../../actions/auth';
import { getPermissionsForRoute } from '../../permissions';
import Layout from '../layout/Layout';
import { PageNotFound } from './shared';
import routes from './routes';
import redirects from './redirects';

const RouteComponent = (props) => {
  const {
    route,
    match,
    match: { params = {} } = {},
    location: { pathname } = {},
  } = props;
  const existingOrUpdatedTenantId = params?.tenantId;
  const {
    auth,
    auth: { accessByTenantId, tenantId },
    global: globals,
  } = useGlobalStates(['auth', 'global']);

  const [noAccessOrInvalidTenant, setNoAccessOrInvalidTenant] = useState(false);
  const permissions = getPermissionsForRoute(route, auth, globals);
  const hasReadAccess = permissions?.canRead;
  const buttons = (route.buttons || [])
    .filter(({ showIf }) => !showIf || showIf(permissions))
    .map(({ component }) => component);
  const accessibleTenantList = Object.keys(accessByTenantId);

  useEffect(() => {
    routeActions.setRoute({
      ...route,
      description: route?.description || null,
      featureId: route?.featureId || null,
      accountBadge: route?.accountBadge || identity,
      breadcrumbs: route?.breadcrumbs || [],
      permissions,
      match,
    });
  }, [JSON.stringify(permissions), tenantId]);

  // Tenant Validation and Redirection
  useEffect(() => {
    if (!pathname || isEmpty(accessByTenantId)) return;
    if (!pathname.startsWith('/tenants/')) {
      let updatedTenantId = tenantId?.toUpperCase();
      const tenantIds = Object.keys(accessByTenantId || {});
      if (!updatedTenantId && tenantIds === 1) {
        const [firstTenantID] = tenantIds.sort();
        updatedTenantId = firstTenantID;
      }
      const redirectUrl = getRedirectUrl(window.location.href);
      const returnTo = redirectUrl.searchParams.get('return_to');
      if (updatedTenantId) {
        const updatedRoute = getReturnPath(updatedTenantId, redirectUrl);
        routeActions.replace(updatedRoute, false);
      } else if (redirectUrl.pathname === '/' && returnTo?.startsWith('/tenants/')) {
        routeActions.replace(returnTo, false);
      } else {
        routeActions.replace(`/tenants/${redirectUrl.search}`, false);
      }
    } else if (pathname.startsWith('/tenants/')) {
      setNoAccessOrInvalidTenant(false);
      const hasAccessAndValidTenantId = accessibleTenantList.includes(existingOrUpdatedTenantId);
      if (hasAccessAndValidTenantId) {
        if (existingOrUpdatedTenantId !== tenantId) {
          setTenantId(existingOrUpdatedTenantId?.toUpperCase(), true);
        }
      } else {
        setNoAccessOrInvalidTenant(true);
      }
    }
  }, [accessByTenantId, pathname, tenantId]);

  const hasValidPathname = pathname && (pathname.startsWith('/tenants/') || pathname === '/');
  return (
    <>
      <Helmet>
        <title>{['Dashboard', tenantId ? route.title : null].filter(Boolean).join(' | ')}</title>
      </Helmet>
      {hasValidPathname ? (
        <Layout
          {...props}
          component={hasReadAccess && !noAccessOrInvalidTenant ? route.Component : PageNotFound}
          mobileComponent={hasReadAccess && !noAccessOrInvalidTenant ? route.MobileComponent : PageNotFound}
          route={{
            ...route,
            buttons,
            permissions,
          }}
        />
      ) : null}
    </>
  );
};

const Router = () => {
  const {
    auth: { tenantId },
  } = useGlobalStates(['auth']);
  return (
    <Switch>
      {redirects.map(({ id, path, to }) => (
        <Route
          key={`redirects-${id}`}
          exact
          path={`/tenants/${tenantId}${path}`}
        >
          <Redirect to={`/tenants/${tenantId}${to}`} />
        </Route>
      ))}
      {routes.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          component={(props) => <RouteComponent route={route} {...props} />}
        />
      ))}
    </Switch>
  );
};

export default Router;
