/* eslint-disable camelcase */
import {
  camelCase,
  find,
  get as lodashGet,
  isEmpty,
  keyBy,
  map,
  mapValues,
  omit,
  reduce,
  snakeCase,
  uniq,
} from 'lodash';
import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import snakeCaseKeys from 'lib-frontend-shared/src/helpers/snakeCaseKeys';
import toNumber from 'lib-frontend-shared/src/helpers/toNumber';
import moment from './helpers/moment';

import * as enums from './enums';
import trim from './helpers/trim';
// FIXME: there should be no date formatting in this file
import formatDate from './helpers/formatDate';

// types
import './types/PartnerLocation';

const { Status } = enums;
const getLocalMoment = (dateTimeInGMT) => (
  dateTimeInGMT
    ? moment(dateTimeInGMT).local()
    : undefined
);

const isoTimestamp = (timestamp) => {
  if (!timestamp) return null;
  return moment(timestamp).toISOString();
};

const get = (obj, path, defaultVal) => lodashGet(obj, path) ?? defaultVal;

const getSteps = (shipment) => {
  const status = get(shipment, 'post_shipping_info.status', '');
  const statusLowerCased = status.toLowerCase().trim();
  const stepStatusMapping = [{
    label: () => 'Order Placed',
    // completed appears with a tick mark in UI
    completed: [
      'draft',
      'pending',
      'error',
      'booked',
      'ready_to_ship',
      'shipped',
      'in_transit',
      'out_for_delivery',
      'awaiting_customer_collection',
      'delivered',
      'delivery_confirmed',
      'failed_collection_attempt',
      'failed_delivery_attempt',
      'ready_for_return',
      'return_in_transit',
      'returned',
      'return_confirmed',
      'missing',
      'delayed',
      'cancelled',
      'cancelled_by_carrier',
      'suspended',
    ],
    // "active" means "some processing is going on". It's highlighted in UI
    active: [],
    error: [],
    // first date/time found in this list will be shown
    dateProp: ['order_date'],
  }, {
    label: () => 'Processed',
    completed: [
      'pending',
      'error',
      'booked',
      'ready_to_ship',
      'shipped',
      'in_transit',
      'out_for_delivery',
      'awaiting_customer_collection',
      'delivered',
      'delivery_confirmed',
      'failed_collection_attempt',
      'failed_delivery_attempt',
      'ready_for_return',
      'return_in_transit',
      'returned',
      'return_confirmed',
      'missing',
      'delayed',
      'cancelled',
      'cancelled_by_carrier',
      'suspended',
    ],
    active: [],
    error: [],
    dateProp: ['confirmation_date'],
    estimatedDateProp: 'estimated_process_date',
    completedDateProp: ['confirmation_date'],
  }, {
    label: () => 'Shipped',
    completed: [
      'shipped',
      'in_transit',
      'out_for_delivery',
      'awaiting_customer_collection',
      'delivered',
      'delivery_confirmed',
      'failed_delivery_attempt',
      'ready_for_return',
      'return_in_transit',
      'returned',
      'return_confirmed',
      'missing',
      'delayed',
      'suspended',
    ],
    active: [],
    error: [
      'error',
      'cancelled',
      'cancelled_by_carrier',
      'failed_collection_attempt',
    ],
    dateProp: [
      'post_shipping_info.key_milestones.failed_collection_attempt',
      'post_shipping_info.key_milestones.shipped',
    ],
    estimatedDateProp: 'post_shipping_info.estimated_ship_date',
    completedDateProp: ['post_shipping_info.key_milestones.shipped'],
  }, {
    label: (state) => {
      if (['ready_for_return', 'return_in_transit', 'returned', 'return_confirmed'].includes(statusLowerCased)) {
        return 'Returned';
      }
      if (state === 'error') {
        return 'Failed Delivery';
      }
      return 'Delivered';
    },
    completed: [
      'delivered',
      'delivery_confirmed',
      'returned',
      'return_confirmed',
    ],
    active: [],
    error: [
      'missing',
      'delayed',
      'cancelled',
      'cancelled_by_carrier',
      'failed_collection_attempt',
      'suspended',
      'failed_delivery_attempt',
    ],
    dateProp: [
      'post_shipping_info.key_milestones.returned',
      'post_shipping_info.key_milestones.return_in_transit',
      'post_shipping_info.key_milestones.ready_for_return',
      'post_shipping_info.key_milestones.delivered',
      'post_shipping_info.key_milestones.failed_delivery_attempt',
    ],
    estimatedDateProp: 'post_shipping_info.estimated_delivery_date',
    completedDateProp: [
      'post_shipping_info.key_milestones.delivered',
      'post_shipping_info.key_milestones.ready_for_return',
      'post_shipping_info.key_milestones.return_in_transit',
      'post_shipping_info.key_milestones.returned',
    ],
  }];
  const steps = stepStatusMapping
    .map(({
      label: labelFunction,
      completed,
      active,
      error = [],
      dateProp,
      estimatedDateProp,
      completedDateProp,
    }) => {
      const states = {
        completed: completed.includes(statusLowerCased),
        active: active.includes(statusLowerCased),
        error: error.includes(statusLowerCased),
      };
      const state = Object.keys(states).filter((key) => states[key])[0] || 'inactive';
      const date = dateProp
        .map((prop) => get(shipment, prop))
        .find(Boolean);
      const estimatedDate = get(shipment, estimatedDateProp);
      const completedDate = (completedDateProp || [])
        .map((prop) => get(shipment, prop))
        .find(Boolean);
      const pastEstimate = ( // true or falsy
        (estimatedDate && moment(estimatedDate).isBefore(completedDate || Date.now()))
      );

      return {
        label: labelFunction(state),
        ...states,
        date,
        estimatedDate,
        completedAndMetEstimate: ( // tri-state - true, false or undefined
          (estimatedDate
          && completedDate && !pastEstimate)
        ),
        outstandingAndPastEstimate: ( // true or falsy
          (!state.completed && pastEstimate)
        ),
      };
    });

  return steps;
};

const getComposedCustomConditions = (conditions, ruleProps) => {
  const perCustomAttributeCondition = conditions
    .filter(({ condition }) => condition.startsWith('customConditionV2'))
    .reduce((acc, { condition, operator }) => ({ ...acc, [condition]: operator }), {});

  return Object.entries(ruleProps)
    .filter(([key, values]) => key.startsWith('customConditionV2') && (values && values.length > 0))
    .map(([key, values]) => ({
      attribute_name: snakeCase(key.replace('customConditionV2', '')),
      values,
      operator: perCustomAttributeCondition[key],
    }));
};

const getDecomposedCustomConditions = (customConditions) => (customConditions || [])
  .filter(({ values }) => values && values.length > 0)
  .reduce((acc, {
    attribute_name,
    operator,
    values,
  }) => ({ ...acc, [camelCase(`customConditionV2-${attribute_name}`)]: { values, operator } }), {});


const getReturnRequestSteps = (returnRequest) => {
  const status = get(returnRequest, 'status', '').toLowerCase().trim();

  const steps = [{
    label: 'Created',
    completed: [
      'approved',
      'cancelled',
      'completed',
      'pending',
      'rejected',
    ],
    completedDate: 'creation_date',
  }];

  const approved = {
    label: 'Approved',
    completed: ['approved', 'cancelled', 'completed'],
    completedDate: 'approval_date',
  };

  if (status === 'rejected') {
    steps.push({
      label: 'Rejected',
      completed: ['rejected'],
      completedDate: 'rejection_date',
    });
  } else if (status === 'cancelled') {
    if (get(returnRequest, 'approval_date')) steps.push(approved);
    steps.push({
      label: 'Cancelled',
      completed: ['cancelled'],
      completedDate: 'cancellation_date',
    });
  } else {
    steps.push(approved, {
      label: 'Completed',
      completed: ['completed'],
      completedDate: 'completion_date',
    });
  }

  return steps.map(({
    label, completed, completedDate,
  }) => ({
    label,
    completed: completed.includes(status),
    date: get(returnRequest, completedDate),
  }));
};

// formatAddress is not perfect as city and state is not converted from code
// to name if available in global store. (and if not available then fetch /cities for country)
const formatAddress = (addressObject) => {
  // eslint-disable-next-line no-param-reassign
  addressObject = addressObject ?? {};
  const props = {
    address1: addressObject.address1,
    area: addressObject.area,
    state: addressObject.state,
    city: addressObject.city,
    country: enums.getShortNameForCountry(addressObject.country),
  };

  return ['address1', 'area', 'state', 'city', 'country']
    .map((field) => props[field])
    .filter(Boolean)
    .join(', ');
};

const dimensionsInOrder = ['width', 'height', 'depth'];

const extractDimensions = (dimensionInfo) => {
  const { unit, ...sizing } = dimensionInfo || {};
  const sizes = dimensionsInOrder.map((dimension) => [
    dimension, toNumber(sizing[dimension]) || 0,
  ]);
  const dimensionUnit = unit || '';
  return {
    ...Object.fromEntries(sizes),
    dimensionUnit,
    dimension: dimensionUnit && sizes.every(([, size]) => !Number.isNaN(size))
      ? `${sizes.map(([, size]) => size).join(' x ')} ${dimensionUnit}`
      : '',
  };
};

export const extractWeight = (weightInfo) => {
  if (weightInfo?.value === undefined || weightInfo?.value === null || weightInfo?.value === '') {
    return {
      weight: null,
      weightUnit: null,
      weightValue: null,
    };
  }
  const value = toNumber(weightInfo?.value) || 0;
  const unit = weightInfo?.unit || '';
  return {
    weight: Number.isNaN(value) || !unit ? '' : `${value} ${unit}`,
    weightUnit: unit,
    weightValue: value,
  };
};

/*
 * A copy of this function is in shipment indexer (keep in sync)
 */
export const createPackageHierarchy = (packages) => {
  const list = packages.map((entry) => ({ ...entry, content: [] }));
  const tree = [list.filter(({ parent }) => !parent)];
  while (true) { // eslint-disable-line
    const level = list.map((entry) => [
      tree.slice(-1)[0].find(({ packageReference }) => (
        packageReference === entry.parent
        // break dependency cycles
        && packageReference !== entry.packageReference
      )),
      entry,
    ]).filter(([parent]) => parent).map(([parent, entry]) => {
      parent.content.push(entry);
      return entry;
    });
    if (!level.length) break;
    tree.push(level);
  }
  const flattened = tree.flatMap((layer) => layer);
  const remaining = list.filter((entry) => !flattened.includes(entry));
  return [...tree[0], ...remaining];
};

export const flattenPackageHierarchy = (packages) => packages.flatMap(
  ({ content, ...parent }) => [
    parent,
    ...flattenPackageHierarchy(
      content.map((child) => ({
        ...child,
        parent: parent.packageReference,
      })),
    ),
  ],
);

export const fromShipment = (shipment = {}) => {
  const status = get(shipment, 'post_shipping_info.status', '');

  const concatFields = ({
    namespace,
    fields,
    delimiter = ', ',
  }) => fields
    .map((field) => get(shipment, [...namespace, field], ''))
    .filter((val) => !!val || val === 0)
    .join(delimiter);

  const paymentAmount = get(shipment, 'payment.pending_amount');
  const paymentCurrency = get(shipment, 'payment.currency');
  const documents = get(shipment, 'post_shipping_info.documents', []);
  const customsAmount = get(shipment, 'customs.declared_value.amount', '');
  const customsCurrency = get(shipment, 'customs.declared_value.currency', '');
  const carrierCommercialInvoice = (
    find(documents, { type: 'commercial_invoice', source: 'carrier' })?.url
    || get(shipment, 'post_shipping_info.commercial_invoice', '')
  );

  const retryMetadata = get(shipment, 'retry_metadata');
  return {
    source: {
      sourceType: get(shipment, 'source.source_type'),
    },
    steps: getSteps(shipment),
    tenant: get(shipment, 'tenant'),
    entityType: get(shipment, 'entity_type'),
    schedulePickupFrom: get(shipment, 'collection.scheduled_from', null),
    schedulePickupTo: get(shipment, 'collection.scheduled_to', null),
    scheduleDropoffFrom: get(shipment, 'delivery.scheduled_from', null),
    scheduleDropoffTo: get(shipment, 'delivery.scheduled_to', null),
    scheduleDropoffDate: get(shipment, 'delivery.scheduled_date', null),
    scheduleStatus: get(shipment, 'schedule_status'),
    orderRef: get(shipment, 'references.partner_order_reference', ''),
    shipmentRef: get(shipment, 'references.partner_shipment_reference', ''),
    alternateRef: get(shipment, 'references.alternate_reference', ''),
    shipmentId: get(shipment, 'shipment_id', ''),
    manifestId: get(shipment, 'manifest_id', ''),
    merchant: get(shipment, 'merchant', ''),
    createdAt: formatDate(get(shipment, 'creation_date', '')),
    updatedAt: formatDate(get(shipment, 'update_date', '')),
    orderDate: formatDate(get(shipment, 'order_date', '')),
    confirmationDate: formatDate(get(shipment, 'confirmation_date', '')),
    status,
    lastStatusRefreshed: isoTimestamp(
      get(shipment, 'post_shipping_info.last_status_refresh_date', ''),
    ),
    customerName: get(shipment, 'dropoff.contact_name', ''),
    orderValue: concatFields({
      namespace: ['payment'],
      fields: ['total_amount', 'currency'],
      delimiter: ' ',
    }),
    preBooked: get(shipment, 'pre_booked', false),
    preBookingInfo: camelCaseKeys(get(shipment, 'pre_booking_info', {})),
    customsValue: concatFields({
      namespace: ['customs', 'declared_value'],
      fields: ['amount', 'currency'],
      delimiter: ' ',
    }),
    pendingAmount: typeof paymentAmount === 'number'
      ? [paymentAmount, paymentCurrency].filter((v) => v !== undefined).join(' ')
      : undefined,
    orderType: get(shipment, 'order_type', ''),
    deliveryType: get(shipment, 'delivery.delivery_type', ''),
    paymentType: enums.getLabelFromValue({
      enumName: 'payment_mode',
      value: get(shipment, 'payment.payment_mode', ''),
    }),
    language: get(shipment, 'language'),
    carrierName: get(shipment, 'carrier_account.carrier', ''),
    carrierId: get(shipment, 'carrier_account.carrier_id', ''),
    carrierAccountName: get(shipment, 'carrier_account.carrier_account_name'),
    carrierStatus: get(shipment, 'post_shipping_info.carrier_status', ''),
    carrierStatusDescription: get(shipment, 'post_shipping_info.carrier_status_description', ''),
    pickupId: get(shipment, 'post_shipping_info.pickup_id', ''),
    carrierTrackingNumber: get(shipment, 'post_shipping_info.tracking_no', ''),
    carrierTrackingUrl: get(shipment, 'post_shipping_info.carrier_tracking_url', ''),
    documentURLs: {
      carriyoPDF: get(shipment, 'post_shipping_info.carriyo_pdf_label_url', ''),
      carrierPDF: get(shipment, 'post_shipping_info.carrier_pdf_label_url', ''),
      carrierZPL: get(shipment, 'post_shipping_info.carrier_zpl_label_url', ''),
      carriyoZPL: get(shipment, 'post_shipping_info.carriyo_zpl_label_url', ''),
      ...(carrierCommercialInvoice ? { carrierCommercialInvoice } : {}),
      default: get(shipment, 'post_shipping_info.default_label_url', ''),
      proofOfDelivery: get(shipment, 'post_shipping_info.proof_of_delivery', ''),
    },
    driverName: get(shipment, 'post_shipping_info.driver_name', ''),
    driverContact: get(shipment, 'post_shipping_info.driver_phone', ''),
    reasonCode: get(shipment, 'post_shipping_info.reason_code', ''),
    recipientName: get(shipment, 'post_shipping_info.recipient_name', ''),
    latestCoords: get(shipment, 'post_shipping_info.coords', []),
    failedDeliveryAttempts: get(shipment, 'post_shipping_info.failed_delivery_attempts', 0),

    documents,
    errors: get(shipment, 'post_shipping_info.error_details', []),

    pickupCompanyName: get(shipment, 'pickup.company_name', ''),
    pickupContactName: get(shipment, 'pickup.contact_name', ''),
    pickupTelephone: get(shipment, 'pickup.contact_phone', ''),
    pickupAlternatePhone: get(shipment, 'pickup.alternate_phone', ''),
    pickupEmail: get(shipment, 'pickup.contact_email', ''),
    pickupAddress1: get(shipment, 'pickup.address1'),
    pickupAddress2: get(shipment, 'pickup.address2'),
    pickupInputArea: get(shipment, 'pickup.input_area'),
    pickupArea: get(shipment, 'pickup.area'),
    pickupInputState: get(shipment, 'pickup.input_state'),
    pickupState: get(shipment, 'pickup.state'),
    pickupLocationId: get(shipment, 'pickup.partner_location_id', ''),
    pickupInputCity: get(shipment, 'pickup.input_city'),
    pickupCity: get(shipment, 'pickup.city'),
    pickupCountry: enums.getShortNameForCountry(get(shipment, 'pickup.country', '')),
    pickupCountryCode: get(shipment, 'pickup.country', ''),
    pickupPostcode: get(shipment, 'pickup.postcode'),
    pickupAddress: formatAddress(get(shipment, 'pickup')),
    pickupW3WAddress: get(shipment, 'pickup.what3words', '').replace(/^\/\/\//, ''),
    pickupCoordinates: get(shipment, 'pickup.coords'),
    pickupNotes: get(shipment, 'pickup.notes', ''),
    pickupPersonalId: get(shipment, 'pickup.personal_id.id'),
    pickupPersonalIdType: get(shipment, 'pickup.personal_id.type'),
    pickupRegistrationNumbers: camelCaseKeys(get(shipment, 'pickup.registration_numbers', [])),

    deliveryCompanyName: get(shipment, 'dropoff.company_name', ''),
    deliveryContactName: get(shipment, 'dropoff.contact_name', ''),
    deliveryTelephone: get(shipment, 'dropoff.contact_phone', ''),
    deliveryAlternatePhone: get(shipment, 'dropoff.alternate_phone', ''),
    deliveryEmail: get(shipment, 'dropoff.contact_email', ''),
    deliveryAddress1: get(shipment, 'dropoff.address1'),
    deliveryAddress2: get(shipment, 'dropoff.address2'),
    deliveryInputArea: get(shipment, 'dropoff.input_area'),
    deliveryArea: get(shipment, 'dropoff.area'),
    deliveryLocationId: get(shipment, 'dropoff.partner_location_id', ''),
    deliveryInputState: get(shipment, 'dropoff.input_state'),
    deliveryState: get(shipment, 'dropoff.state'),
    deliveryInputCity: get(shipment, 'dropoff.input_city'),
    deliveryCity: get(shipment, 'dropoff.city'),
    deliveryCountry: enums.getShortNameForCountry(get(shipment, 'dropoff.country', '')),
    deliveryCountryCode: get(shipment, 'dropoff.country', ''),
    deliveryPostcode: get(shipment, 'dropoff.postcode'),
    deliveryAddress: formatAddress(get(shipment, 'dropoff')),
    deliveryCoordinates: get(shipment, 'dropoff.coords'),
    deliveryW3WAddress: get(shipment, 'dropoff.what3words', '').replace(/^\/\/\//, ''),
    deliveryCollectionPointId: get(shipment, 'dropoff.collection_point_id'),
    deliveryPersonalId: get(shipment, 'dropoff.personal_id.id'),
    deliveryPersonalIdType: get(shipment, 'dropoff.personal_id.type'),
    deliveryNotes: get(shipment, 'dropoff.notes', ''),
    deliveryRegistrationNumbers: camelCaseKeys(get(shipment, 'dropoff.registration_numbers', [])),

    customsAmount,
    customsCurrency,
    customsDeclaredValue: customsAmount ? `${customsAmount} ${customsCurrency}` : undefined,
    customsInstructions: get(shipment, 'customs.instructions', ''),
    customsDeclarationStatement: get(shipment, 'customs.declaration_statement', ''),
    customsSeller: camelCaseKeys(get(shipment, 'customs.seller', {})),
    customsBuyer: camelCaseKeys(get(shipment, 'customs.buyer', {})),
    customsImporter: camelCaseKeys(get(shipment, 'customs.importer', {})),
    customsExporter: camelCaseKeys(get(shipment, 'customs.exporter', {})),
    customAttributes: get(shipment, 'custom_attributes') || undefined,
    customAttributesKeyword: get(shipment, 'custom_attributes_keyword', []).reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {}),
    totalParcels: get(shipment, 'parcel_count'),
    products: get(shipment, 'items', []).map(({
      sku = '',
      description = '',
      notes = '',
      quantity = '',
      price: {
        amount = '',
        currency = '',
      } = {},
      cost: {
        amount: costAmount = '',
        currency: costCurrency = '',
      } = {},
      battery = {},
      manufacturer_id: manufacturerId,
      weight,
      hs_code: hsCode,
      barcode,
      image_link: imageLink,
      origin_country: originCountry,
      dangerous_goods: dangerousGoods,
      original_shipment_id: originalShipmentId,
    }, index) => ({
      number: index + 1,
      sku,
      description,
      notes,
      quantity,
      unitPrice: `${amount} ${currency}`,
      costPrice: `${costAmount} ${costCurrency}`,
      hsCode,
      barcode,
      originalShipmentId,
      originCountry,
      dangerousGoods,
      imageLink,
      battery: camelCaseKeys(battery),
      manufacturerId,
      ...extractWeight(weight),
    })),
    parcels: get(shipment, 'parcels', []).map(({
      description,
      dimension,
      weight,
      partner_parcel_reference,
      parcel_items: parcelItems = [],
    } = {}, index) => ({
      description,
      number: index + 1,
      partnerReference: partner_parcel_reference,
      parcelItems,
      ...extractDimensions(dimension),
      ...extractWeight(weight),
    })),
    freight: {
      packages: createPackageHierarchy(
        get(shipment, 'freight.packages', []).map(({
          package_reference: packageReference,
          type,
          parent,
          dimension,
          weight,
        }) => ({
          packageReference,
          parent,
          type,
          ...extractDimensions(dimension),
          ...extractWeight(weight),
        })),
      ),
    },
    estimatedProcessDate: getLocalMoment(get(shipment, 'estimated_process_date')),
    estimatedShipDate: getLocalMoment(get(shipment, 'post_shipping_info.estimated_ship_date')),
    estimatedDeliveryDate: getLocalMoment(get(shipment, 'post_shipping_info.estimated_delivery_date')),
    promisedDeliveryDate: getLocalMoment(get(shipment, 'promised_delivery_date')),
    originalPromisedDeliveryDate: getLocalMoment(get(shipment, 'original_promised_delivery_date')),

    estimatedShippingCostAmount: get(shipment, 'estimated_shipping_cost.amount'),
    estimatedShippingCostCurrency: get(shipment, 'estimated_shipping_cost.currency'),
    estimatedShippingCost: concatFields({
      namespace: ['estimated_shipping_cost'],
      fields: ['amount', 'currency'],
      delimiter: ' ',
    }),
    estimatedShippingCostBreakdown: get(shipment, 'estimated_shipping_cost.breakdown', []),
    milestones: get(shipment, 'post_shipping_info.key_milestones', {}),
    labelStatus: get(shipment, 'post_shipping_info.async_statuses.label', ''),
    asyncStatuses: get(shipment, 'post_shipping_info.async_statuses', {}),

    retry: {
      booking: {
        count: get(retryMetadata, 'booking.retry_count', 0),
        scheduledAt: get(retryMetadata, 'booking.next_retry'),
      },
    },

    // fields that are needed and should be in its original form
    pickup: get(shipment, 'pickup'),
    dropoff: get(shipment, 'dropoff'),
    carrier_account: get(shipment, 'carrier_account'),
    isClickNShip: Boolean(get(shipment, 'clicknship')),
  };
};

export const createAlternativeShipmentFromPayload = (rawShipment, targetType, prebookParam) => {
  const {
    pickup,
    dropoff,
    payment: {
      // ignore because not relevant to reverse shipment
      pending_amount,
      payment_mode,
      ...allOtherPaymentFields
    },

    // stuff to ignore
    // ignore because new shipment should re-create these
    shipment_id,
    creation_date,
    update_date,
    post_shipping_info,
    // ignore because not relevant to reverse shipment
    entity_type,
    order_date,
    confirmation_date,
    items = [],
    estimated_process_date,
    promised_delivery_date,
    delivery,
    custom_attributes,
    pre_booked,

    ...allOthers
  } = rawShipment;

  const derivedLocationFields = ['area', 'city', 'state'].map((type) => `input_${type}`);

  const [pickupData, dropoffData] = entity_type === targetType ? [pickup, dropoff] : [dropoff, pickup];
  const isCreatingReverseShipment = targetType === 'REVERSE';
  return {
    entity_type: targetType,
    pickup: omit(pickupData, derivedLocationFields),
    dropoff: omit(dropoffData, derivedLocationFields),
    payment: allOtherPaymentFields,
    order_date: moment().toISOString(),
    items: items.map((item) => ({ ...item, original_shipment_id: isCreatingReverseShipment ? shipment_id : undefined })),
    ...allOthers,
    // reset carrier because we want auto carrier selection as default
    carrier_account: {},
    pre_booked: prebookParam === 'PRESERVE' ? pre_booked : prebookParam,
  };
};

export const fromReturnRequest = (returnRequest = {}) => {
  const status = get(returnRequest, 'status', '');

  const productAdditionalInfo = keyBy(get(returnRequest, 'return_items_updates', []), 'sku');
  const items = get(returnRequest, 'items', []).map(({
    sku = '',
    requested_quantity: requestedQuantity = 0,
    approved_quantity: approvedQuantity = 0,
    returned_quantity: returnedQuantity = 0,
    received_quantity: receivedQuantity = 0,
    return_comments: returnComments = '',
    pre_defined_return_comments: preDefinedReturnComments = [],
    return_images: returnImages = [],
    return_item_condition: returnItemCondition,
    return_reason: returnReason,
    resolution,
    rejection_reason: rejectionReason,
    dropoff,
    image_link: imageLink,
  }, index) => {
    const moreInfo = productAdditionalInfo[sku];
    return {
      number: index + 1,
      sku,
      description: moreInfo?.description || '',
      unitPrice: moreInfo?.price?.amount !== undefined
        ? `${moreInfo.price.amount} ${moreInfo.price.currency || ''}`.trim()
        : '',
      currency: moreInfo?.price?.currency,
      requestedQuantity,
      approvedQuantity,
      returnedQuantity,
      receivedQuantity,
      returnComments,
      preDefinedReturnComments,
      returnImages,
      returnItemCondition,
      returnReason,
      resolution,
      rejectionReason,
      dropoff: {
        ...camelCaseKeys(dropoff || {}),
        address: formatAddress(dropoff),
      },
      imageLink,
    };
  });

  return {
    steps: getReturnRequestSteps(returnRequest),

    returnRequestId: get(returnRequest, 'return_request_id', ''),
    merchant: get(returnRequest, 'merchant', ''),
    orderRef: get(returnRequest, 'partner_order_reference', ''),
    reverseShipmentIds: uniq(map(get(returnRequest, 'items', []), 'reverse_shipment_id').filter(Boolean)),
    createdAt: formatDate(get(returnRequest, 'creation_date', '')),
    updatedAt: formatDate(get(returnRequest, 'update_date', '')),

    approvedAt: formatDate(get(returnRequest, 'approval_date', '')),
    rejectedAt: formatDate(get(returnRequest, 'rejection_date', '')),
    completedAt: formatDate(get(returnRequest, 'completion_date', '')),
    cancelledAt: formatDate(get(returnRequest, 'cancellation_date', '')),

    channel: get(returnRequest, 'channel', ''),
    collection: camelCaseKeys(get(returnRequest, 'collection', {})),
    customerComment: get(returnRequest, 'customer_comment', ''),
    resolution: get(returnRequest, 'resolution', ''),
    refundMethod: get(returnRequest, 'refund_method', ''),

    status,

    pickupLocationId: get(returnRequest, 'pickup.partner_location_id'),
    pickupContactName: get(returnRequest, 'pickup.contact_name', ''),
    pickupTelephone: get(returnRequest, 'pickup.contact_phone', ''),
    alternatePhone: get(returnRequest, 'pickup.alternate_phone', ''),
    pickupEmail: get(returnRequest, 'pickup.contact_email', ''),
    pickupAddress: formatAddress(get(returnRequest, 'pickup')),
    pickupAddress1: get(returnRequest, 'pickup.address1'),
    pickupAddress2: get(returnRequest, 'pickup.address2'),
    pickupState: get(returnRequest, 'pickup.state'),
    pickupCity: get(returnRequest, 'pickup.city'),
    pickupCountry: enums.getShortNameForCountry(get(returnRequest, 'pickup.country', '')),
    pickupCountryCode: get(returnRequest, 'pickup.country', ''),
    pickupPostcode: get(returnRequest, 'pickup.postcode'),
    pickupCoordinates: get(returnRequest, 'pickup.coords'),
    pickupType: get(returnRequest, 'pickup.type'),
    pickupPersonalId: get(returnRequest, 'pickup.personal_id.id'),
    pickupPersonalIdType: get(returnRequest, 'pickup.personal_id.type'),

    items,
    refunds: camelCaseKeys(get(returnRequest, 'refund_info.refunds')),
    notes: returnRequest.notes,
  };
};

export const fromRule = (rule = {}) => {
  const {
    rule_id,
    rule_set_id,
    entity_type,
    carrier_accounts = [],
    carrier_choice = null,
    round_robin_carriers = null,
    status,
    rule_name,
    gross_weight,
    parcel_count,
    item_value,
    volumetric_weight,
    chargeable_weight,
    description,
    sequence,
    daily_limit = {},
    order_value,

    dangerous_goods,
    customer_address_verified,
    schedule,
    custom_conditions_v2 = [],
  } = rule || {};

  // remove null
  const dangerousGoods = dangerous_goods ?? undefined;
  const customerAddressVerified = customer_address_verified ?? undefined;

  const {
    order_type: {
      operator: orderTypeOperator,
      value: orderTypes,
    },
    merchant: {
      operator: merchantOperator,
      value: merchant,
    },
    delivery_type: {
      operator: deliveryTypeOperator,
      value: deliveryTypes,
    },
    payment_type: {
      operator: paymentTypeOperator,
      value: paymentTypes,
    },
    pickup_postcode: {
      operator: pickupPostcodeOperator,
      value: pickupPostcode,
    },
    dropoff_postcode: {
      operator: dropoffPostcodeOperator,
      value: dropoffPostcode,
    },
    pickup_v2: {
      operator: pickupOperator,
      value: pickupValue,
    },
    dropoff_v2: {
      operator: dropoffOperator,
      value: dropoffValue,
    },
    pickup_partner_location_ids: {
      operator: pickupPartnerLocationOperator,
      value: pickupLocations,
    },
    dropoff_partner_location_ids: {
      operator: dropoffPartnerLocationOperator,
      value: dropoffLocations,
    },
    source_type: {
      operator: sourceTypeOperator,
      value: sourceType,
    },
  } = [
    'order_type',
    'merchant',
    'delivery_type',
    'payment_type',
    'pickup_postcode',
    'dropoff_postcode',
    'pickup_v2',
    'dropoff_v2',
    'pickup_partner_location_ids',
    'dropoff_partner_location_ids',
    'source_type',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: rule[prop]?.operator || '',
      value: rule[prop]?.value || [],
    },
  }), {});

  const customConditions = getDecomposedCustomConditions(custom_conditions_v2);

  return {
    rowId: rule_id,
    ruleId: rule_id,
    ruleSetId: rule_set_id,
    carrierAccounts: camelCaseKeys(carrier_accounts),
    carrierChoice: carrier_choice,
    roundRobinCarriers: camelCaseKeys(round_robin_carriers || []),
    entityType: entity_type,
    merchant,
    active: status === Status.active,
    name: rule_name || '',
    description: description || '',
    priority: sequence || 0,
    schedule: camelCaseKeys(schedule),
    dailyLimit: camelCaseKeys(daily_limit) || {},
    // conditions
    orderValue: {
      from: order_value ? order_value.min_value : 0,
      to: order_value ? order_value.max_value : null,
    },
    orderTypes,
    deliveryTypes,
    paymentTypes,
    pickupPostcode,
    dropoffPostcode,
    dangerousGoods,
    itemValue: {
      from: item_value ? item_value.min_value : 0,
      to: item_value ? item_value.max_value : null,
    },
    parcelCount: {
      from: parcel_count ? parcel_count.min_value : 0,
      to: parcel_count ? parcel_count.max_value : null,
    },
    grossWeight: {
      from: gross_weight ? gross_weight.min_value : 0,
      to: gross_weight ? gross_weight.max_value : null,
    },
    chargeableWeight: {
      from: chargeable_weight ? chargeable_weight.min_value : 0,
      to: chargeable_weight ? chargeable_weight.max_value : null,
    },
    volumetricWeight: {
      from: volumetric_weight ? volumetric_weight.min_value : 0,
      to: volumetric_weight ? volumetric_weight.max_value : null,
    },
    customerAddressVerified,
    dropoff: camelCaseKeys(dropoffValue),
    pickup: camelCaseKeys(pickupValue),
    pickupLocations,
    dropoffLocations,
    sourceType,
    ...(reduce(customConditions, (acc, { values }, condition) => ({ ...acc, [condition]: values }), {})
    ),
    conditions: [
      ...(order_value ? [{ condition: 'orderValue', operator: '' }] : []),
      ...(orderTypes.length > 0 ? [{ condition: 'orderTypes', operator: orderTypeOperator }] : []),
      ...(deliveryTypes.length > 0 ? [{ condition: 'deliveryTypes', operator: deliveryTypeOperator }] : []),
      ...(merchant && merchantOperator ? [{ condition: 'merchant', operator: merchantOperator }] : []),
      ...(paymentTypes.length > 0 ? [{ condition: 'paymentTypes', operator: paymentTypeOperator }] : []),
      ...(pickupPostcode.length > 0 ? [{ condition: 'pickupPostcode', operator: pickupPostcodeOperator }] : []),
      ...(dropoffPostcode.length > 0 ? [{ condition: 'dropoffPostcode', operator: dropoffPostcodeOperator }] : []),
      ...(dangerousGoods !== undefined ? [{ condition: 'dangerousGoods', operator: '' }] : []),
      ...(parcel_count ? [{ condition: 'parcelCount', operator: '' }] : []),
      ...(item_value ? [{ condition: 'itemValue', operator: '' }] : []),
      ...(gross_weight ? [{ condition: 'grossWeight', operator: '' }] : []),
      ...(chargeable_weight ? [{ condition: 'chargeableWeight', operator: '' }] : []),
      ...(volumetric_weight ? [{ condition: 'volumetricWeight', operator: '' }] : []),
      ...(customerAddressVerified !== undefined ? [{ condition: 'customerAddressVerified', operator: '' }] : []),
      ...(pickupValue.length > 0 ? [{ condition: 'pickup', operator: pickupOperator }] : []),
      ...(dropoffValue.length > 0 ? [{ condition: 'dropoff', operator: dropoffOperator }] : []),
      ...(pickupLocations.length ? [{ condition: 'pickupLocations', operator: pickupPartnerLocationOperator }] : []),
      ...(dropoffLocations.length ? [{ condition: 'dropoffLocations', operator: dropoffPartnerLocationOperator }] : []),
      ...(sourceType.length ? [{ condition: 'sourceType', operator: sourceTypeOperator }] : []),
      ...(!isEmpty(customConditions)
        ? Object.entries(customConditions).map(([condition, { operator }]) => ({
          condition,
          operator,
        }))
        : []
      ),
    ],
  };
};

export const fromRuleSet = (ruleSet = {}) => {
  const merchants = get(ruleSet, 'merchants', []);
  const countries = get(ruleSet, 'countries', []);
  const isAnyMerchant = merchants.includes('_ANY');
  const isAnyCountries = countries.includes('_ANY');
  return ({
    ...camelCaseKeys(ruleSet),
    merchants: isAnyMerchant ? [] : merchants,
    countries: isAnyCountries ? [] : countries,
  });
};

export const toRuleSet = (ruleSet = {}) => {
  const merchants = get(ruleSet, 'merchants', []);
  const countries = get(ruleSet, 'countries', []);
  return ({
    ...snakeCaseKeys(ruleSet),
    merchants: merchants.length > 0 ? merchants : ['_ANY'],
    countries: countries.length > 0 ? countries : ['_ANY'],
  });
};

export const toRule = (rule = {}) => {
  const {
    ruleId,
    ruleSetId,
    entityType: entity_type,
    merchant = [],
    status = rule.active ? Status.active : Status.inactive,
    name: rule_name,
    description,
    priority: sequence,
    carrierAccounts,
    carrierChoice,
    roundRobinCarriers = [],
    dailyLimit,
    dailyLimit: {
      threshold,
    } = {},
    orderValue: {
      from: order_value_min,
      to: order_value_max,
    },
    orderTypes = [],
    deliveryTypes = [],
    paymentTypes = [],
    pickupPostcode = [],
    dropoffPostcode = [],
    dangerousGoods: dangerous_goods,
    parcelCount: {
      from: parcel_count_value_min = 0,
      to: parcel_count_value_max = null,
    } = {},
    itemValue: {
      from: item_value_min,
      to: item_value_max,
    } = {},
    grossWeight: {
      from: gross_weight_value_min = 0,
      to: gross_weight_value_max = null,
    } = {},
    chargeableWeight: {
      from: chargeable_weight_value_min = 0,
      to: chargeable_weight_value_max = null,
    } = {},
    volumetricWeight: {
      from: volumetric_weight_value_min = 0,
      to: volumetric_weight_value_max = null,
    } = {},

    customerAddressVerified: customer_address_verified,
    pickup = [],
    dropoff = [],
    pickupLocations = [],
    dropoffLocations = [],
    sourceType = [],
    conditions = [],
    schedule,
    ...restRuleProps
  } = rule;
  const customConditions = getComposedCustomConditions(conditions, restRuleProps);
  const [
    order_type,
    delivery_type,
    merchantValue,
    payment_type,
    source_type,
    pickup_postcode,
    dropoff_postcode,
  ] = [
    orderTypes,
    deliveryTypes,
    merchant,
    paymentTypes,
    sourceType,
    pickupPostcode,
    dropoffPostcode,
  ].map((arr) => (arr.length === 0 ? null : arr));

  const [
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
  ] = [
    pickupLocations,
    dropoffLocations,
  ].map((arr) => (arr.length === 0 ? null : arr));

  const sanitizedCarrierChoice = carrierAccounts.length > 1 ? carrierChoice : null;

  return trim({
    rule_id: ruleId,
    rule_set_id: ruleSetId,
    entity_type,
    merchant: merchantValue || [],
    status,
    rule_name,
    description,
    sequence,
    carrier_choice: sanitizedCarrierChoice,
    round_robin_carriers: snakeCaseKeys(
      sanitizedCarrierChoice === 'ROUND_ROBIN'
      && roundRobinCarriers?.length
        ? roundRobinCarriers
        : null,
    ),
    carrier_accounts: snakeCaseKeys(carrierAccounts),
    schedule: snakeCaseKeys(schedule),
    ...((!isEmpty(dailyLimit) && threshold >= 0) ? {
      daily_limit: {
        threshold,
      },
    } : {}),
    ...(find(conditions, { condition: 'orderValue' }) && (order_value_min !== null || order_value_max !== null) ? {
      order_value: {
        ...(order_value_min !== null ? { min_value: order_value_min } : {}),
        ...(order_value_max !== null ? { max_value: order_value_max } : {}),
      },
    } : {
      order_value: null,
    }),
    ...(find(conditions, { condition: 'itemValue' }) && (item_value_min !== null || item_value_max !== null) ? {
      item_value: {
        ...(item_value_min !== null ? { min_value: item_value_min } : {}),
        ...(item_value_max !== null ? { max_value: item_value_max } : {}),
      },
    } : {
      item_value: null,
    }),
    ...(find(conditions, { condition: 'orderTypes' }) ? {
      order_type: {
        operator: find(conditions, { condition: 'orderTypes' })?.operator || '',
        value: order_type,
      },
    } : {
      order_type: null,
    }),
    ...(find(conditions, { condition: 'deliveryTypes' }) ? {
      delivery_type: {
        operator: find(conditions, { condition: 'deliveryTypes' })?.operator || '',
        value: delivery_type,
      },
    } : {
      delivery_type: null,
    }),
    ...(find(conditions, { condition: 'merchant' }) ? {
      merchant: {
        operator: find(conditions, { condition: 'merchant' })?.operator || '',
        value: merchantValue || [],
      },
    } : {
      merchant: null,
    }),
    ...(find(conditions, { condition: 'paymentTypes' }) ? {
      payment_type: {
        operator: find(conditions, { condition: 'paymentTypes' })?.operator || '',
        value: payment_type,
      },
    } : {
      payment_type: null,
    }),
    ...(find(conditions, { condition: 'pickupPostcode' }) ? {
      pickup_postcode: {
        operator: find(conditions, { condition: 'pickupPostcode' })?.operator || '',
        value: pickup_postcode,
      },
    } : {
      pickup_postcode: null,
    }),
    ...(find(conditions, { condition: 'dropoffPostcode' }) ? {
      dropoff_postcode: {
        operator: find(conditions, { condition: 'dropoffPostcode' })?.operator || '',
        value: dropoff_postcode,
      },
    } : {
      dropoff_postcode: null,
    }),
    ...(find(conditions, { condition: 'dangerousGoods' }) ? {
      dangerous_goods,
    } : {
      dangerous_goods: null,
    }),
    ...(find(conditions, { condition: 'customerAddressVerified' }) ? {
      customer_address_verified,
    } : {
      customer_address_verified: null,
    }),
    ...(find(conditions, { condition: 'pickup' }) ? {
      pickup_v2: {
        operator: find(conditions, { condition: 'pickup' }).operator || '',
        value: snakeCaseKeys(pickup),
      },
    } : {
      pickup_v2: null,
    }),
    ...(find(conditions, { condition: 'dropoff' }) ? {
      dropoff_v2: {
        operator: find(conditions, { condition: 'dropoff' }).operator || '',
        value: snakeCaseKeys(dropoff),
      },
    } : {
      dropoff_v2: null,
    }),
    ...(find(conditions, { condition: 'pickupLocations' }) ? {
      pickup_partner_location_ids: {
        operator: find(conditions, { condition: 'pickupLocations' })?.operator || '',
        value: pickup_partner_location_ids,
      },
    } : {
      pickup_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'dropoffLocations' }) ? {
      dropoff_partner_location_ids: {
        operator: find(conditions, { condition: 'dropoffLocations' })?.operator || '',
        value: dropoff_partner_location_ids,
      },
    } : {
      dropoff_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'sourceType' }) ? {
      source_type: {
        operator: find(conditions, { condition: 'sourceType' })?.operator || '',
        value: source_type,
      },
    } : {
      source_type: null,
    }),
    ...(find(conditions, { condition: 'parcelCount' }) ? {
      parcel_count: {
        min_value: parcel_count_value_min,
        max_value: parcel_count_value_max,
      },
    } : {
      parcel_count: null,
    }),
    ...(find(conditions, { condition: 'grossWeight' }) ? {
      gross_weight: {
        min_value: gross_weight_value_min,
        max_value: gross_weight_value_max,
      },
    } : {
      gross_weight: null,
    }),
    ...(find(conditions, { condition: 'chargeableWeight' }) ? {
      chargeable_weight: {
        min_value: chargeable_weight_value_min,
        max_value: chargeable_weight_value_max,
      },
    } : {
      chargeable_weight: null,
    }),
    ...(find(conditions, { condition: 'volumetricWeight' }) ? {
      volumetric_weight: {
        min_value: volumetric_weight_value_min,
        max_value: volumetric_weight_value_max,
      },
    } : {
      volumetric_weight: null,
    }),
    custom_conditions_v2: snakeCaseKeys(customConditions),
  });
};


export const fromCapacityRule = (rule = {}) => {
  const {
    capacity_profile_id,
    capacity_id,
    name,
    entity_type,
    threshold,
    sequence,
    status,
    special_start_date,
    special_end_date,
    days,
    custom_conditions_v2 = [],
  } = rule || {};


  const {
    pickup_postcode: {
      operator: pickupPostcodeOperator,
      value: pickupPostcode,
    },
    dropoff_postcode: {
      operator: dropoffPostcodeOperator,
      value: dropoffPostcode,
    },
    pickup_v2: {
      operator: pickupOperator,
      value: pickupValue,
    },
    dropoff_v2: {
      operator: dropoffOperator,
      value: dropoffValue,
    },
    pickup_partner_location_ids: {
      operator: pickupPartnerLocationOperator,
      value: pickupLocations,
    },
    dropoff_partner_location_ids: {
      operator: dropoffPartnerLocationOperator,
      value: dropoffLocations,
    },
    source_type: {
      operator: sourceTypeOperator,
      value: sourceType,
    },
  } = [
    'pickup_postcode',
    'dropoff_postcode',
    'pickup_v2',
    'dropoff_v2',
    'pickup_partner_location_ids',
    'dropoff_partner_location_ids',
    'source_type',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: rule[prop]?.operator || '',
      value: rule[prop]?.value || [],
    },
  }), {});

  const customConditions = getDecomposedCustomConditions(custom_conditions_v2);

  return {
    capacityProfileId: capacity_profile_id,
    capacityId: capacity_id,
    name,
    entityType: entity_type,
    threshold,
    sequence,
    status,
    specialStartDate: special_start_date,
    specialEndDate: special_end_date,
    days: days || [],
    // conditions
    dropoff: camelCaseKeys(dropoffValue),
    pickup: camelCaseKeys(pickupValue),
    pickupLocations,
    dropoffLocations,
    pickupPostcode,
    dropoffPostcode,
    sourceType,
    ...(reduce(customConditions, (acc, { values }, condition) => ({ ...acc, [condition]: values }), {})),
    conditions: [
      ...(pickupValue.length > 0 ? [{ condition: 'pickup', operator: pickupOperator }] : []),
      ...(dropoffValue.length > 0 ? [{ condition: 'dropoff', operator: dropoffOperator }] : []),
      ...(pickupPostcode.length > 0 ? [{ condition: 'pickupPostcode', operator: pickupPostcodeOperator }] : []),
      ...(dropoffPostcode.length > 0 ? [{ condition: 'dropoffPostcode', operator: dropoffPostcodeOperator }] : []),
      ...(pickupLocations.length ? [{ condition: 'pickupLocations', operator: pickupPartnerLocationOperator }] : []),
      ...(dropoffLocations.length ? [{ condition: 'dropoffLocations', operator: dropoffPartnerLocationOperator }] : []),
      ...(sourceType.length ? [{ condition: 'sourceType', operator: sourceTypeOperator }] : []),
      ...(!isEmpty(customConditions)
        ? Object.entries(customConditions).map(([condition, { operator }]) => ({
          condition,
          operator,
        }))
        : []
      ),
    ],
  };
};


export const toCapacityRule = (rule = {}) => {
  const {
    capacityProfileId,
    capacityId,
    entityType,
    name,
    threshold,
    sequence,
    status = rule.active ? Status.active : Status.inactive,
    specialStartDate,
    specialEndDate,
    days,

    pickup = [],
    dropoff = [],
    pickupPostcode = [],
    dropoffPostcode = [],
    pickupLocations = [],
    dropoffLocations = [],
    sourceType = [],
    conditions = [],
    ...restRuleProps
  } = rule;
  const customConditions = getComposedCustomConditions(conditions, restRuleProps);
  const source_type = sourceType.length ? sourceType : null;
  const [
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
    pickup_postcode,
    dropoff_postcode,
  ] = [
    pickupLocations,
    dropoffLocations,
    pickupPostcode,
    dropoffPostcode,
  ].map((arr) => (arr.length === 0 ? null : arr));

  return {
    capacity_profile_id: capacityProfileId,
    capacity_id: capacityId,
    name,
    entity_type: entityType,
    threshold,
    sequence,
    status,
    special_start_date: specialStartDate,
    special_end_date: specialEndDate,
    days,
    ...(find(conditions, { condition: 'pickup' }) ? {
      pickup_v2: {
        operator: find(conditions, { condition: 'pickup' }).operator || '',
        value: snakeCaseKeys(pickup),
      },
    } : {
      pickup_v2: null,
    }),
    ...(find(conditions, { condition: 'dropoff' }) ? {
      dropoff_v2: {
        operator: find(conditions, { condition: 'dropoff' }).operator || '',
        value: snakeCaseKeys(dropoff),
      },
    } : {
      dropoff_v2: null,
    }),
    ...(find(conditions, { condition: 'pickupPostcode' }) ? {
      pickup_postcode: {
        operator: find(conditions, { condition: 'pickupPostcode' })?.operator || '',
        value: pickup_postcode,
      },
    } : {
      pickup_postcode: null,
    }),
    ...(find(conditions, { condition: 'dropoffPostcode' }) ? {
      dropoff_postcode: {
        operator: find(conditions, { condition: 'dropoffPostcode' })?.operator || '',
        value: dropoff_postcode,
      },
    } : {
      dropoff_postcode: null,
    }),
    ...(find(conditions, { condition: 'pickupLocations' }) ? {
      pickup_partner_location_ids: {
        operator: find(conditions, { condition: 'pickupLocations' })?.operator || '',
        value: pickup_partner_location_ids,
      },
    } : {
      pickup_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'dropoffLocations' }) ? {
      dropoff_partner_location_ids: {
        operator: find(conditions, { condition: 'dropoffLocations' })?.operator || '',
        value: dropoff_partner_location_ids,
      },
    } : {
      dropoff_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'sourceType' }) ? {
      source_type: {
        operator: find(conditions, { condition: 'sourceType' })?.operator || '',
        value: source_type,
      },
    } : {
      source_type: null,
    }),
    custom_conditions_v2: snakeCaseKeys(customConditions),
  };
};

export const fromCapacityProfile = (profile = {}) => {
  const { carrier_account_ids, ...otherProps } = profile;
  return {
    ...camelCaseKeys(otherProps),
    carrierIds: carrier_account_ids,
  };
};

export const toCapacityProfile = (profile = {}) => {
  const { carrierIds, ...otherProps } = profile;
  return {
    ...snakeCaseKeys(otherProps),
    carrier_account_ids: carrierIds,
  };
};

const convertToStringIfNotEmpty = (value) => {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return value.toString();
  }
  if (Array.isArray(value)) {
    return value;
  }
  if (value !== null && typeof value === 'object') {
    const result = {};
    Object.keys(value).forEach((key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(key)) {
        result[key] = convertToStringIfNotEmpty(value[key]);
      }
    });
    return result;
  }

  return value;
};
export const fromCostingRule = (rule = {}) => {
  const {
    costing_profile_id,
    rule_id,
    name,
    entity_type,
    sequence,
    status,
    gross_weight,
    volumetric_weight,
    dangerous_goods,
    effective_date,
    expiry_date,
    cost_structure,
    schedule,
    custom_conditions_v2 = [],
  } = rule || {};
  const fixedCost = get(cost_structure, 'fixed_cost');
  const costStructure = convertToStringIfNotEmpty({
    ...cost_structure,
    structureType: fixedCost ? 'fixedCost' : 'variableCost',
    codSurchargeApplied: Boolean(get(cost_structure, 'cod_surcharge.fixed_cost') || get(cost_structure, 'cod_surcharge.percentage_cost')),
    codSurchargeType: get(cost_structure, 'cod_surcharge.percentage_cost') ? 'percentageCost' : 'fixedCost',
  });

  // remove null
  const dangerousGoods = dangerous_goods ?? undefined;

  const {
    order_type: {
      operator: orderTypeOperator,
      value: orderTypes,
    },
    delivery_type: {
      operator: deliveryTypeOperator,
      value: deliveryTypes,
    },
    payment_type: {
      operator: paymentTypeOperator,
      value: paymentTypes,
    },
    pickup_postcode: {
      operator: pickupPostcodeOperator,
      value: pickupPostcode,
    },
    dropoff_postcode: {
      operator: dropoffPostcodeOperator,
      value: dropoffPostcode,
    },
    pickup_v2: {
      operator: pickupOperator,
      value: pickupValue,
    },
    dropoff_v2: {
      operator: dropoffOperator,
      value: dropoffValue,
    },
    pickup_partner_location_ids: {
      operator: pickupPartnerLocationOperator,
      value: pickupLocations,
    },
    dropoff_partner_location_ids: {
      operator: dropoffPartnerLocationOperator,
      value: dropoffLocations,
    },
    source_type: {
      operator: sourceTypeOperator,
      value: sourceType,
    },
  } = [
    'order_type',
    'delivery_type',
    'payment_type',
    'pickup_postcode',
    'dropoff_postcode',
    'pickup_v2',
    'dropoff_v2',
    'pickup_partner_location_ids',
    'dropoff_partner_location_ids',
    'source_type',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: rule[prop]?.operator || '',
      value: rule[prop]?.value || [],
    },
  }), {});

  const customConditions = getDecomposedCustomConditions(custom_conditions_v2);

  return {
    costingProfileId: costing_profile_id,
    ruleId: rule_id,
    name,
    entityType: entity_type,
    sequence,
    status,
    effectiveDate: effective_date,
    expiryDate: expiry_date,
    costPerShipment: fixedCost,
    schedule: camelCaseKeys(schedule),
    // conditions
    orderTypes,
    deliveryTypes,
    paymentTypes,
    pickupPostcode,
    dropoffPostcode,
    dangerousGoods,
    grossWeight: {
      from: gross_weight ? gross_weight.min_value : 0,
      to: gross_weight ? gross_weight.max_value : null,
    },
    volumetricWeight: {
      from: volumetric_weight ? volumetric_weight.min_value : 0,
      to: volumetric_weight ? volumetric_weight.max_value : null,
    },
    dropoff: camelCaseKeys(dropoffValue),
    pickup: camelCaseKeys(pickupValue),
    pickupLocations,
    dropoffLocations,
    sourceType,
    ...(reduce(customConditions, (acc, { values }, condition) => ({ ...acc, [condition]: values }), {})),
    costStructure: camelCaseKeys(costStructure),
    conditions: [
      ...(orderTypes.length > 0 ? [{ condition: 'orderTypes', operator: orderTypeOperator }] : []),
      ...(deliveryTypes.length > 0 ? [{ condition: 'deliveryTypes', operator: deliveryTypeOperator }] : []),
      ...(paymentTypes.length > 0 ? [{ condition: 'paymentTypes', operator: paymentTypeOperator }] : []),
      ...(pickupPostcode.length > 0 ? [{ condition: 'pickupPostcode', operator: pickupPostcodeOperator }] : []),
      ...(dropoffPostcode.length > 0 ? [{ condition: 'dropoffPostcode', operator: dropoffPostcodeOperator }] : []),
      ...(dangerousGoods !== undefined ? [{ condition: 'dangerousGoods', operator: '' }] : []),
      ...(gross_weight ? [{ condition: 'grossWeight', operator: '' }] : []),
      ...(volumetric_weight ? [{ condition: 'volumetricWeight', operator: '' }] : []),
      ...(pickupValue.length > 0 ? [{ condition: 'pickup', operator: pickupOperator }] : []),
      ...(dropoffValue.length > 0 ? [{ condition: 'dropoff', operator: dropoffOperator }] : []),
      ...(pickupLocations.length ? [{ condition: 'pickupLocations', operator: pickupPartnerLocationOperator }] : []),
      ...(dropoffLocations.length ? [{ condition: 'dropoffLocations', operator: dropoffPartnerLocationOperator }] : []),
      ...(sourceType.length ? [{ condition: 'sourceType', operator: sourceTypeOperator }] : []),
      ...(!isEmpty(customConditions)
        ? Object.entries(customConditions).map(([condition, { operator }]) => ({
          condition,
          operator,
        }))
        : []
      ),
    ],
  };
};


export const toCostingRule = (rule = {}) => {
  const {
    costingProfileId,
    ruleId,
    entityType,
    name,
    sequence,
    status = rule.active ? Status.active : Status.inactive,
    schedule,
    dangerousGoods: dangerous_goods,
    costStructure,
    grossWeight: {
      from: gross_weight_value_min = 0,
      to: gross_weight_value_max = null,
    } = {},
    volumetricWeight: {
      from: volumetric_weight_value_min = 0,
      to: volumetric_weight_value_max = null,
    } = {},
    effectiveDate,
    expiryDate,
    orderTypes = [],
    deliveryTypes = [],
    paymentTypes = [],
    pickupPostcode = [],
    dropoffPostcode = [],
    pickup = [],
    dropoff = [],
    pickupLocations = [],
    dropoffLocations = [],
    sourceType = [],
    conditions = [],
    ...restRuleProps
  } = rule;
  const customConditions = getComposedCustomConditions(conditions, restRuleProps);
  const sanitizedCostStructure = snakeCaseKeys(omit(costStructure, ['structureType', 'codSurchargeApplied', 'codSurchargeType']));
  const fixedCost = get(sanitizedCostStructure, 'fixed_cost');
  const [
    order_type,
    delivery_type,
    payment_type,
    source_type,
    pickup_postcode,
    dropoff_postcode,
  ] = [
    orderTypes,
    deliveryTypes,
    paymentTypes,
    sourceType,
    pickupPostcode,
    dropoffPostcode,
  ].map((arr) => (arr.length === 0 ? null : arr));

  const [
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
  ] = [
    pickupLocations,
    dropoffLocations,
  ].map((arr) => (arr.length === 0 ? null : arr));

  return {
    costing_profile_id: costingProfileId,
    rule_id: ruleId,
    name,
    entity_type: entityType,
    sequence,
    status,
    effective_date: effectiveDate ? moment(effectiveDate).toISOString() : null,
    expiry_date: expiryDate ? moment(expiryDate).toISOString() : null,
    cost_per_shipment: fixedCost,
    schedule: snakeCaseKeys(schedule),
    cost_structure: sanitizedCostStructure,
    ...(find(conditions, { condition: 'pickup' }) ? {
      pickup_v2: {
        operator: find(conditions, { condition: 'pickup' }).operator || '',
        value: snakeCaseKeys(pickup),
      },
    } : {
      pickup_v2: null,
    }),
    ...(find(conditions, { condition: 'dropoff' }) ? {
      dropoff_v2: {
        operator: find(conditions, { condition: 'dropoff' }).operator || '',
        value: snakeCaseKeys(dropoff),
      },
    } : {
      dropoff_v2: null,
    }),
    ...(find(conditions, { condition: 'pickupLocations' }) ? {
      pickup_partner_location_ids: {
        operator: find(conditions, { condition: 'pickupLocations' })?.operator || '',
        value: pickup_partner_location_ids,
      },
    } : {
      pickup_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'dropoffLocations' }) ? {
      dropoff_partner_location_ids: {
        operator: find(conditions, { condition: 'dropoffLocations' })?.operator || '',
        value: dropoff_partner_location_ids,
      },
    } : {
      dropoff_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'sourceType' }) ? {
      source_type: {
        operator: find(conditions, { condition: 'sourceType' })?.operator || '',
        value: source_type,
      },
    } : {
      source_type: null,
    }),
    ...(find(conditions, { condition: 'grossWeight' }) ? {
      gross_weight: {
        min_value: gross_weight_value_min,
        max_value: gross_weight_value_max,
      },
    } : {
      gross_weight: null,
    }),
    ...(find(conditions, { condition: 'volumetricWeight' }) ? {
      volumetric_weight: {
        min_value: volumetric_weight_value_min,
        max_value: volumetric_weight_value_max,
      },
    } : {
      volumetric_weight: null,
    }),
    ...(find(conditions, { condition: 'orderTypes' }) ? {
      order_type: {
        operator: find(conditions, { condition: 'orderTypes' })?.operator || '',
        value: order_type,
      },
    } : {
      order_type: null,
    }),
    ...(find(conditions, { condition: 'deliveryTypes' }) ? {
      delivery_type: {
        operator: find(conditions, { condition: 'deliveryTypes' })?.operator || '',
        value: delivery_type,
      },
    } : {
      delivery_type: null,
    }),
    ...(find(conditions, { condition: 'paymentTypes' }) ? {
      payment_type: {
        operator: find(conditions, { condition: 'paymentTypes' })?.operator || '',
        value: payment_type,
      },
    } : {
      payment_type: null,
    }),
    ...(find(conditions, { condition: 'pickupPostcode' }) ? {
      pickup_postcode: {
        operator: find(conditions, { condition: 'pickupPostcode' })?.operator || '',
        value: pickup_postcode,
      },
    } : {
      pickup_postcode: null,
    }),
    ...(find(conditions, { condition: 'dropoffPostcode' }) ? {
      dropoff_postcode: {
        operator: find(conditions, { condition: 'dropoffPostcode' })?.operator || '',
        value: dropoff_postcode,
      },
    } : {
      dropoff_postcode: null,
    }),
    ...(find(conditions, { condition: 'dangerousGoods' }) ? {
      dangerous_goods,
    } : {
      dangerous_goods: null,
    }),
    custom_conditions_v2: snakeCaseKeys(customConditions),
  };
};

export const fromCostingProfile = (profile = {}) => {
  const { carrier_account_ids, ...otherProps } = profile;
  return {
    ...camelCaseKeys(otherProps),
    carrierIds: carrier_account_ids,
  };
};

export const toCostingProfile = (profile = {}) => {
  const { carrierIds, ...otherProps } = profile;
  return {
    ...snakeCaseKeys(otherProps),
    carrier_account_ids: carrierIds,
  };
};

export const fromCarrier = ({
  account_country: accountCountry,
  account_currency: accountCurrency,
  carrier_account_id: carrierId,
  clicknship_service_id: clicknshipServiceId,
  carrier: name,
  carrier_account_name: accountName,
  merchants = null,
  auto_ready_to_ship: autoReadyToShip,
  auto_translate_to_english: autoTranslateToEnglish,
  property,
  carrier_custom_attributes: carrierCustomAttributes,
  label,
  network,
  network_id: networkId,
  daily_capacity_id: dailyCapacityProfileId,
  in_flight_capacity_id: inFlightCapacityProfileId,
  costing_profile_id: costingProfileId,
  use_carrier_costing_api: useCarrierCostingApi = false,
  generic_carrier: genericCarrier = false,
  service_schedule: rawServiceSchedule,
  status,
  parent = {},
  service_name: serviceName,
  service_description: serviceDescription,
  shipping_cost_markup_percentage: shippingCostMarkupPercentage,
  delivery_promise: deliveryPromise,
  estimated_days: estimatedDays = 0,
  packaging,
  assets = {},
}) => {
  const {
    default_label: defaultLabel,
  } = label || {};

  const serviceSchedule = rawServiceSchedule
    ? reduce(
      rawServiceSchedule,
      (acc, props, country) => {
        const {
          blocked_days: blockedDays = [],
          calendar_v2: calendar,
          ...rest
        } = props;
        return {
          ...acc,
          [country]: {
            ...camelCaseKeys(rest),
            blockedDays,
            calendar: calendar ? reduce(
              calendar,
              (acc, val, key) => ({ // eslint-disable-line no-shadow
                ...acc,
                [key]: camelCaseKeys(val),
              }),
              {},
            ) : undefined,
          },
        };
      },
      {},
    ) : undefined;

  return {
    accountCountry,
    accountCurrency,
    carrierId,
    clicknshipServiceId,
    name,
    accountName,
    merchants: merchants?.length && !merchants.includes('_ANY') ? merchants : null,
    autoReadyToShip,
    autoTranslateToEnglish,
    property,
    carrier_custom_attributes: carrierCustomAttributes,
    label: { defaultLabel },
    network,
    networkId,
    dailyCapacityProfileId,
    inFlightCapacityProfileId,
    costingProfileId,
    genericCarrier,
    serviceSchedule,
    status,
    parent: camelCaseKeys(parent),
    assets: camelCaseKeys(assets),
    useCarrierCostingApi,
    shippingCostMarkupPercentage,
    serviceName,
    serviceDescription,
    deliveryPromise,
    estimatedDays,
    packaging: { // set undefined so changing to 'null' implies pending change
      defaultParcelDimension: packaging?.default_parcel_dimension || undefined,
      defaultParcelWeight: packaging?.default_parcel_weight || undefined,
    },
  };
};

export const fromCarrierStats = ({
  last_booking_date: lastBookingDate,
  last_error_date: lastErrorDate,
  last_real_time_update_applied_date: lastRealTimeUpdateAppliedDate,
  last_real_time_update_received_date: lastRealTimeUpdateReceivedDate,
  last_sync_update_applied_date: lastSyncUpdateAppliedDate,
  last_sync_update_received_date: lastSyncUpdateReceivedDate,
  ...rest
}) => ({
  ...fromCarrier(rest),
  lastBookingDate,
  lastErrorDate,
  lastRealTimeUpdateAppliedDate,
  lastRealTimeUpdateReceivedDate,
  lastSyncUpdateAppliedDate,
  lastSyncUpdateReceivedDate,
});

export const toCarrier = ({
  accountCountry,
  accountCurrency,
  carrierId,
  name,
  accountName,
  merchants = null,
  autoReadyToShip,
  autoTranslateToEnglish,
  property,
  carrier_custom_attributes: carrierCustomAttributes,
  label,
  network,
  networkId,
  dailyCapacityProfileId,
  inFlightCapacityProfileId,
  costingProfileId,
  genericCarrier = false,
  serviceSchedule: rawServiceSchedule,
  useCarrierCostingApi = false,
  status,
  parent,
  assets,
  shippingCostMarkupPercentage,
  serviceName,
  serviceDescription,
  deliveryPromise,
  estimatedDays,
  packaging: {
    defaultParcelDimension = null,
    defaultParcelWeight = null,
  } = {},
}) => {
  const {
    defaultLabel,
  } = label || {};

  const serviceSchedule = rawServiceSchedule
    ? reduce(
      rawServiceSchedule,
      (acc, props, country) => {
        const { calendar, ...rest } = props;
        return {
          ...acc,
          [country]: {
            ...snakeCaseKeys(rest),
            calendar_v2: calendar ? reduce(
              calendar,
              (acc, val, key) => ({ // eslint-disable-line no-shadow
                ...acc,
                [key]: snakeCaseKeys(val),
              }),
              {},
            ) : undefined,
          },
        };
      },
      {},
    ) : undefined;

  return {
    account_country: accountCountry,
    account_currency: accountCurrency,
    carrier_account_id: carrierId,
    carrier: name,
    carrier_account_name: accountName,
    merchants: merchants?.length ? merchants : ['_ANY'],
    auto_ready_to_ship: autoReadyToShip,
    auto_translate_to_english: autoTranslateToEnglish,
    property,
    carrier_custom_attributes: carrierCustomAttributes,
    label: {
      default_label: defaultLabel,
    },
    network: (Array.isArray(network) && network.length) ? network : null,
    network_id: networkId,
    daily_capacity_id: dailyCapacityProfileId,
    in_flight_capacity_id: inFlightCapacityProfileId,
    costing_profile_id: costingProfileId,
    generic_carrier: genericCarrier,
    service_schedule: serviceSchedule,
    use_carrier_costing_api: useCarrierCostingApi,
    status,
    parent: snakeCaseKeys(parent),
    assets: snakeCaseKeys(assets),
    service_name: serviceName,
    service_description: serviceDescription,
    shipping_cost_markup_percentage: shippingCostMarkupPercentage,
    delivery_promise: deliveryPromise,
    estimated_days: estimatedDays,
    packaging: {
      default_parcel_dimension: defaultParcelDimension,
      default_parcel_weight: defaultParcelWeight,
    },
  };
};

export const fromNetwork = ({
  network, // unused
  network_v2,
  ...otherProps
}) => {
  const {
    pickup_postcode: {
      operator: pickupPostcodeOperator,
      value: pickupPostcode,
    },
    dropoff_postcode: {
      operator: dropoffPostcodeOperator,
      value: dropoffPostcode,
    },
  } = [
    'pickup_postcode',
    'dropoff_postcode',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: otherProps[prop]?.operator || '',
      value: otherProps[prop]?.value || [],
    },
  }), {});
  return ({
    ...camelCaseKeys(otherProps),
    pickupPostcode,
    dropoffPostcode,
    network: camelCaseKeys(network_v2),
    conditions: [
      ...(pickupPostcode.length > 0 ? [{ condition: 'pickupPostcode', operator: pickupPostcodeOperator }] : []),
      ...(dropoffPostcode.length > 0 ? [{ condition: 'dropoffPostcode', operator: dropoffPostcodeOperator }] : []),
    ],
  });
};

export const toNetwork = ({
  network,
  pickupPostcode = [],
  dropoffPostcode = [],
  conditions = [],
  ...otherProps
}) => {
  const [
    pickup_postcode,
    dropoff_postcode,
  ] = [
    pickupPostcode,
    dropoffPostcode,
  ].map((arr) => (arr.length === 0 ? null : arr));
  return ({
    ...snakeCaseKeys(otherProps),
    ...(find(conditions, { condition: 'pickupPostcode' }) ? {
      pickup_postcode: {
        operator: find(conditions, { condition: 'pickupPostcode' })?.operator || '',
        value: pickup_postcode,
      },
    } : {
      pickup_postcode: null,
    }),
    ...(find(conditions, { condition: 'dropoffPostcode' }) ? {
      dropoff_postcode: {
        operator: find(conditions, { condition: 'dropoffPostcode' })?.operator || '',
        value: dropoff_postcode,
      },
    } : {
      dropoff_postcode: null,
    }),
    network_v2: snakeCaseKeys(network),
  });
};

export const fromCustomAttribute = ({
  custom_attribute_id,
  attribute_entity_type,
  merchants = null,
  attribute_name: name,
  attribute_type: type,
  allowed_values,
  indexed,
}) => ({
  customAttributeId: custom_attribute_id,
  attributeEntityType: attribute_entity_type,
  merchants,
  name,
  type,
  allowedValues: allowed_values,
  indexed,
});

export const toCustomAttribute = ({
  customAttributeId,
  attributeEntityType,
  merchants = null,
  name,
  type,
  allowedValues,
}) => ({
  custom_attribute_id: customAttributeId,
  attribute_entity_type: attributeEntityType,
  merchants,
  attribute_name: name,
  attribute_type: type,
  allowed_values: allowedValues,
});


export const fromServiceLevelSet = (serviceLevelSet = {}) => {
  const merchants = get(serviceLevelSet, 'merchants', []);
  const countries = get(serviceLevelSet, 'countries', []);
  const isAnyMerchant = merchants.includes('_ANY');
  const isAnyCountries = countries.includes('_ANY');
  return ({
    ...camelCaseKeys(serviceLevelSet),
    merchants: isAnyMerchant ? [] : merchants,
    countries: isAnyCountries ? [] : countries,
  });
};

export const toServiceLevelSet = (serviceLevelSet = {}) => {
  const merchants = get(serviceLevelSet, 'merchants', []);
  const countries = get(serviceLevelSet, 'countries', []);
  return ({
    ...snakeCaseKeys(serviceLevelSet),
    merchants: merchants.length > 0 ? merchants : ['_ANY'],
    countries: countries.length > 0 ? countries : ['_ANY'],
  });
};

export const fromServiceLevelsConfig = (serviceLevels = {}) => {
  const {
    rule_id,
    rule_set_id,
    entity_type,
    config_type,
    status,
    name,
    description,
    sequence,
    lead_time,
    schedule,
    custom_conditions_v2 = [],
  } = serviceLevels;


  // remove null
  const {
    carrier_ids: {
      operator: carrierIdsOperator,
      value: carrierIds,
    },
    merchants: {
      operator: merchantOperator,
      value: merchant,
    },
    order_types: {
      operator: orderTypesOperator,
      value: orderTypes,
    },
    delivery_types: {
      operator: deliveryTypesOperator,
      value: deliveryTypes,
    },
    pickup_postcode: {
      operator: pickupPostcodeOperator,
      value: pickupPostcode,
    },
    dropoff_postcode: {
      operator: dropoffPostcodeOperator,
      value: dropoffPostcode,
    },
    dropoff_v2: {
      operator: dropoffOperator,
      value: dropoffValue,
    },
    pickup_v2: {
      operator: pickupOperator,
      value: pickupValue,
    },
    pickup_partner_location_ids: {
      operator: pickupPartnerLocationOperator,
      value: pickupLocations,
    },
    dropoff_partner_location_ids: {
      operator: dropoffPartnerLocationOperator,
      value: dropoffLocations,
    },
    source_type: {
      operator: sourceTypeOperator,
      value: sourceType,
    },
  } = [
    'carrier_ids',
    'merchants',
    'order_types',
    'delivery_types',
    'pickup_postcode',
    'dropoff_postcode',
    'dropoff_v2',
    'pickup_v2',
    'pickup_partner_location_ids',
    'dropoff_partner_location_ids',
    'source_type',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: serviceLevels[prop]?.operator || '',
      value: serviceLevels[prop]?.value || [],
    },
  }), {});

  const customConditions = getDecomposedCustomConditions(custom_conditions_v2);

  return {
    configId: rule_id,
    ruleSetId: rule_set_id,
    entityType: entity_type,
    configType: config_type,
    merchant,
    active: status === Status.active,
    name: name || '',
    description: description || '',
    sequence: sequence || 0,
    leadTime: lead_time,
    // conditions
    carrierIds,
    orderTypes,
    deliveryTypes,
    pickupPostcode,
    dropoffPostcode,
    dropoff: camelCaseKeys(dropoffValue),
    pickup: camelCaseKeys(pickupValue),
    schedule: camelCaseKeys(schedule),
    pickupLocations,
    dropoffLocations,
    sourceType,
    ...(reduce(customConditions, (acc, { values }, condition) => ({ ...acc, [condition]: values }), {})),
    conditions: [
      ...(orderTypes.length > 0 ? [{ condition: 'orderTypes', operator: orderTypesOperator }] : []),
      ...(deliveryTypes.length > 0 ? [{ condition: 'deliveryTypes', operator: deliveryTypesOperator }] : []),
      ...(pickupPostcode.length > 0 ? [{ condition: 'pickupPostcode', operator: pickupPostcodeOperator }] : []),
      ...(dropoffPostcode.length > 0 ? [{ condition: 'dropoffPostcode', operator: dropoffPostcodeOperator }] : []),
      ...(carrierIds.length > 0 ? [{ condition: 'carrierIds', operator: carrierIdsOperator }] : []),
      ...(merchant && merchantOperator ? [{ condition: 'merchant', operator: merchantOperator }] : []),
      ...(pickupLocations.length ? [{ condition: 'pickupLocations', operator: pickupPartnerLocationOperator }] : []),
      ...(dropoffLocations.length ? [{ condition: 'dropoffLocations', operator: dropoffPartnerLocationOperator }] : []),
      ...(pickupValue.length > 0 ? [{ condition: 'pickup', operator: pickupOperator }] : []),
      ...(dropoffValue.length > 0 ? [{ condition: 'dropoff', operator: dropoffOperator }] : []),
      ...(sourceType.length > 0 ? [{ condition: 'sourceType', operator: sourceTypeOperator }] : []),
      ...(!isEmpty(customConditions)
        ? Object.entries(customConditions).map(([condition, { operator }]) => ({
          condition,
          operator,
        }))
        : []
      ),
    ],
  };
};

export const toServiceLevelsConfig = (serviceLevels = {}) => {
  const {
    configId: rule_id,
    entityType: entity_type = 'SHIPMENT',
    configType: config_type,
    merchant = [],
    ruleSetId,
    status = serviceLevels.active ? Status.active : Status.inactive,
    name,
    description,
    sequence,
    leadTime: lead_time,
    schedule,
    carrierIds = [],
    orderTypes = [],
    deliveryTypes = [],
    pickupPostcode = [],
    dropoffPostcode = [],
    pickup = [],
    dropoff = [],
    pickupLocations = [],
    dropoffLocations = [],
    sourceType = [],
    conditions = [],
    ...restRuleProps
  } = serviceLevels;
  const customConditions = getComposedCustomConditions(conditions, restRuleProps);
  const [
    carrier_ids,
    merchantValue,
    order_types,
    delivery_types,
    source_type,
  ] = [
    carrierIds,
    merchant,
    orderTypes,
    deliveryTypes,
    sourceType,
  ].map((arr) => (arr.length === 0 ? null : arr));

  const [
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
    pickup_postcode,
    dropoff_postcode,
  ] = [
    pickupLocations,
    dropoffLocations,
    pickupPostcode,
    dropoffPostcode,
  ].map((arr) => (arr.length === 0 ? null : arr));

  return {
    rule_id,
    rule_set_id: ruleSetId,
    entity_type,
    config_type,
    merchants: merchantValue || [],
    status,
    name,
    description,
    sequence,
    lead_time,
    schedule: snakeCaseKeys(schedule),
    ...(find(conditions, { condition: 'carrierIds' }) ? {
      carrier_ids: {
        operator: find(conditions, { condition: 'carrierIds' })?.operator || '',
        value: carrier_ids,
      },
    } : {
      carrier_ids: null,
    }),
    ...(find(conditions, { condition: 'orderTypes' }) ? {
      order_types: {
        operator: find(conditions, { condition: 'orderTypes' })?.operator || '',
        value: order_types,
      },
    } : {
      order_types: null,
    }),
    ...(find(conditions, { condition: 'deliveryTypes' }) ? {
      delivery_types: {
        operator: find(conditions, { condition: 'deliveryTypes' })?.operator || '',
        value: delivery_types,
      },
    } : {
      delivery_types: null,
    }),
    ...(find(conditions, { condition: 'pickupPostcode' }) ? {
      pickup_postcode: {
        operator: find(conditions, { condition: 'pickupPostcode' })?.operator || '',
        value: pickup_postcode,
      },
    } : {
      pickup_postcode: null,
    }),
    ...(find(conditions, { condition: 'dropoffPostcode' }) ? {
      dropoff_postcode: {
        operator: find(conditions, { condition: 'dropoffPostcode' })?.operator || '',
        value: dropoff_postcode,
      },
    } : {
      dropoff_postcode: null,
    }),
    ...(find(conditions, { condition: 'merchant' }) ? {
      merchants: {
        operator: find(conditions, { condition: 'merchant' })?.operator || '',
        value: merchantValue || [],
      },
    } : {
      merchants: null,
    }),
    ...(find(conditions, { condition: 'pickup' }) ? {
      pickup_v2: {
        operator: find(conditions, { condition: 'pickup' }).operator || '',
        value: snakeCaseKeys(pickup),
      },
    } : {
      pickup_v2: null,
    }),
    ...(find(conditions, { condition: 'dropoff' }) ? {
      dropoff_v2: {
        operator: find(conditions, { condition: 'dropoff' }).operator || '',
        value: snakeCaseKeys(dropoff),
      },
    } : {
      dropoff_v2: null,
    }),
    ...(find(conditions, { condition: 'pickupLocations' }) ? {
      pickup_partner_location_ids: {
        operator: find(conditions, { condition: 'pickupLocations' })?.operator || '',
        value: pickup_partner_location_ids,
      },
    } : {
      pickup_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'dropoffLocations' }) ? {
      dropoff_partner_location_ids: {
        operator: find(conditions, { condition: 'dropoffLocations' })?.operator || '',
        value: dropoff_partner_location_ids,
      },
    } : {
      dropoff_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'sourceType' }) ? {
      source_type: {
        operator: find(conditions, { condition: 'sourceType' })?.operator || '',
        value: source_type,
      },
    } : {
      source_type: null,
    }),
    custom_conditions_v2: snakeCaseKeys(customConditions),
  };
};


export const fromWebhookConfig = (config) => {
  const {
    authentication: nullableAuthentication,
    headers,
    pickup,
    dropoff,
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
    delivery_type,
    order_type,
    source_type,
    update_source_type,
    creation_source_type,
    shipment_type: shipmentType = undefined,
    merchants,
    custom_conditions_v2 = [],
    ...otherProps
  } = config;
  ['notify_status'].forEach((prop) => {
    otherProps[prop] = otherProps[prop] || [];
  });
  const {
    pickup_v2: {
      operator: pickupOperator,
      value: pickupValue,
    },
    dropoff_v2: {
      operator: dropoffOperator,
      value: dropoffValue,
    },
    pickup_partner_location_ids: {
      operator: pickupPartnerLocationOperator,
      value: pickupLocations,
    },
    dropoff_partner_location_ids: {
      operator: dropoffPartnerLocationOperator,
      value: dropoffLocations,
    },
    delivery_type: {
      operator: deliveryTypeOperator,
      value: deliveryTypes,
    },
    order_type: {
      operator: orderTypeOperator,
      value: orderTypes,
    },
    source_type: {
      operator: sourceTypeOperator,
      value: sourceType,
    },
    update_source_type: {
      operator: updateSourceTypeOperator,
      value: updateSourceType,
    },
    creation_source_type: {
      operator: creationSourceTypeOperator,
      value: creationSourceType,
    },
  } = [
    'pickup_v2',
    'dropoff_v2',
    'pickup_partner_location_ids',
    'dropoff_partner_location_ids',
    'delivery_type',
    'order_type',
    'source_type',
    'update_source_type',
    'creation_source_type',
  ].reduce((acc, prop) => ({
    ...acc,
    [prop]: {
      operator: config[prop]?.operator || '',
      value: config[prop]?.value || [],
    },
  }), {});

  const {
    custom_parameters: customParameters,
    custom_headers: customHeaders,
    ...restAuthentication
  } = nullableAuthentication || {};

  const customConditions = getDecomposedCustomConditions(custom_conditions_v2);

  return {
    authentication: {
      customParameters,
      customHeaders,
      ...camelCaseKeys(restAuthentication),
    },
    headers,
    merchants: merchants?.length && !merchants.includes('_ANY') ? merchants : null,
    // conditions
    dropoff: camelCaseKeys(dropoffValue),
    pickup: camelCaseKeys(pickupValue),
    pickupLocations,
    dropoffLocations,
    deliveryTypes,
    orderTypes,
    sourceType,
    updateSourceType,
    creationSourceType,
    shipmentType,
    ...(reduce(customConditions, (acc, { values }, condition) => ({ ...acc, [condition]: values }), {})),
    conditions: [
      ...(pickupValue.length > 0 ? [{ condition: 'pickup', operator: pickupOperator }] : []),
      ...(dropoffValue.length > 0 ? [{ condition: 'dropoff', operator: dropoffOperator }] : []),
      ...(shipmentType ? [{ condition: 'shipmentType', operator: '' }] : []),
      ...(pickupLocations.length ? [{ condition: 'pickupLocations', operator: pickupPartnerLocationOperator }] : []),
      ...(dropoffLocations.length ? [{ condition: 'dropoffLocations', operator: dropoffPartnerLocationOperator }] : []),
      ...(deliveryTypes.length > 0 ? [{ condition: 'deliveryTypes', operator: deliveryTypeOperator }] : []),
      ...(orderTypes.length > 0 ? [{ condition: 'orderTypes', operator: orderTypeOperator }] : []),
      ...(sourceType.length ? [{ condition: 'sourceType', operator: sourceTypeOperator }] : []),
      ...(updateSourceType.length ? [{ condition: 'updateSourceType', operator: updateSourceTypeOperator }] : []),
      ...(creationSourceType.length ? [{ condition: 'creationSourceType', operator: creationSourceTypeOperator }] : []),
      ...(!isEmpty(customConditions)
        ? Object.entries(customConditions).map(([condition, { operator }]) => ({
          condition,
          operator,
        }))
        : []
      ),
    ],
    ...camelCaseKeys(otherProps),
  };
};

export const toWebhookConfig = (config) => {
  const {
    authentication = {},
    headers,
    pickup = [],
    dropoff = [],
    pickupLocations = [],
    dropoffLocations = [],
    deliveryTypes = [],
    orderTypes = [],
    sourceType = [],
    updateSourceType = [],
    creationSourceType = [],
    shipmentType = undefined,
    conditions = [],
    merchants,
    ...restProps
  } = config;
  const customConditions = getComposedCustomConditions(conditions, restProps);
  const otherProps = Object.entries(restProps)
    .filter(([key]) => !key.startsWith('customConditionV2'))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const [
    pickup_partner_location_ids,
    dropoff_partner_location_ids,
  ] = [
    pickupLocations,
    dropoffLocations,
  ].map((arr) => (arr.length === 0 ? null : arr));
  ['notifyStatus'].forEach((prop) => {
    if (Array.isArray(otherProps[prop]) && !otherProps[prop].length) {
      otherProps[prop] = null;
    }
  });
  const {
    customHeaders = {},
    customParameters = {},
    ...restAuthentication
  } = authentication;
  return {
    authentication: {
      custom_headers: customHeaders,
      custom_parameters: customParameters,
      ...snakeCaseKeys(restAuthentication),
    },
    headers,
    merchants: merchants?.length ? merchants : ['_ANY'],
    ...snakeCaseKeys(otherProps),
    ...(find(conditions, { condition: 'pickup' }) ? {
      pickup_v2: {
        operator: find(conditions, { condition: 'pickup' }).operator || '',
        value: snakeCaseKeys(pickup),
      },
    } : {
      pickup_v2: null,
    }),
    ...(find(conditions, { condition: 'dropoff' }) ? {
      dropoff_v2: {
        operator: find(conditions, { condition: 'dropoff' }).operator || '',
        value: snakeCaseKeys(dropoff),
      },
    } : {
      dropoff_v2: null,
    }),
    ...(find(conditions, { condition: 'pickupLocations' }) ? {
      pickup_partner_location_ids: {
        operator: find(conditions, { condition: 'pickupLocations' })?.operator || '',
        value: pickup_partner_location_ids,
      },
    } : {
      pickup_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'dropoffLocations' }) ? {
      dropoff_partner_location_ids: {
        operator: find(conditions, { condition: 'dropoffLocations' })?.operator || '',
        value: dropoff_partner_location_ids,
      },
    } : {
      dropoff_partner_location_ids: null,
    }),
    ...(find(conditions, { condition: 'deliveryTypes' }) && deliveryTypes.length > 0 ? {
      delivery_type: {
        operator: find(conditions, { condition: 'deliveryTypes' })?.operator || '',
        value: deliveryTypes,
      },
    } : {
      delivery_type: null,
    }),
    ...(find(conditions, { condition: 'orderTypes' }) && orderTypes.length > 0 ? {
      order_type: {
        operator: find(conditions, { condition: 'orderTypes' })?.operator || '',
        value: orderTypes,
      },
    } : {
      order_type: null,
    }),
    ...(find(conditions, { condition: 'sourceType' }) && sourceType.length > 0 ? {
      source_type: {
        operator: find(conditions, { condition: 'sourceType' })?.operator || '',
        value: sourceType,
      },
    } : {
      source_type: null,
    }),
    ...(find(conditions, { condition: 'creationSourceType' }) && creationSourceType.length > 0 ? {
      creation_source_type: {
        operator: find(conditions, { condition: 'creationSourceType' })?.operator || '',
        value: creationSourceType,
      },
    } : {
      creation_source_type: null,
    }),
    ...(find(conditions, { condition: 'updateSourceType' }) && updateSourceType.length > 0 ? {
      update_source_type: {
        operator: find(conditions, { condition: 'updateSourceType' })?.operator || '',
        value: updateSourceType,
      },
    } : {
      update_source_type: null,
    }),
    ...(find(conditions, { condition: 'shipmentType' }) ? {
      shipment_type: shipmentType,
    } : {
      shipment_type: undefined,
    }),
    custom_conditions_v2: snakeCaseKeys(customConditions),
  };
};

export const fromOrderType = (config) => camelCaseKeys(config);

export const toOrderType = (config) => snakeCaseKeys(config);

export const fromDeliveryType = (config) => camelCaseKeys(config);

export const toDeliveryType = (config) => snakeCaseKeys(config);

/**
 * @param {Object} obj
 * @returns {PartnerLocation}
 */
export const fromLocation = ({ merchants = null, ...rest }) => ({
  merchants: merchants?.length && !merchants.includes('_ANY') ? merchants : null,
  ...camelCaseKeys(rest),
});

/**
 * @param {PartnerLocation} obj
 * @returns {Object}
 */
export const toLocation = ({
  merchants = null,
  address1,
  address2,
  locationCode,
  ...rest
}) => ({
  ...snakeCaseKeys(rest),
  merchants: merchants?.length ? merchants : ['_ANY'],
  // lodash's snake case for address1 is address_1.. but backend model keeps it as address1
  address1,
  address2,
  location_code: locationCode || undefined,
});

/**
 * @param {object} param
 * @param {string} param.city_code
 * @param {{language: string, name: string}[]} param.city_names
 * @returns {{ label: string, value: string }}
 */
export const fromCity = ({
  city_code: cityCode,
  city_names: cityNames = [],
} = {}) => ({
  label: (cityNames.find(({ language }) => language === 'en') || {}).name || cityCode,
  value: cityCode,
});

export const fromState = ({
  state_code: stateCode,
  state_names: stateNames = [],
} = {}) => ({
  label: (stateNames.find(({ language }) => language === 'en') || {}).name || stateCode,
  value: stateCode,
});

const defaultReturnRequestSetting = {
  defaultDropoffLocations: {},
  shopifyConnectorId: undefined,
  shopifyIntegrationEnabled: false,
  notificationUsers: [],
  orderLookupMethods: [],
  enableAutoApproval: false,
  enableCollectionDateScheduling: false,
};

export const fromReturnRequestSetting = ({
  allow_custom_comment: allowCustomComment,
  allowed_days_for_return: allowedDaysForReturn,
  allow_pickup_location_selection: allowPickupLocationSelection,
  allow_pre_defined_comments: allowPreDefinedComments,
  allow_undelivered_items: allowUndeliveredItems,
  default_dropoff_locations: defaultDropoffLocations = defaultReturnRequestSetting.defaultDropoffLocations,
  shopify_connector_id: shopifyConnectorId = defaultReturnRequestSetting.shopifyConnectorId,
  shopify_integration_enabled: shopifyIntegrationEnabled = defaultReturnRequestSetting.shopifyIntegrationEnabled,
  notification_users: notificationUsers = defaultReturnRequestSetting.notificationUsers,
  order_lookup_methods: orderLookupMethods = defaultReturnRequestSetting.orderLookupMethods,
  collection_schedule_by_country: collectionScheduleByCountry,
}) => ({
  allowCustomComment,
  allowedDaysForReturn,
  allowPickupLocationSelection,
  allowPreDefinedComments,
  allowUndeliveredItems,
  defaultDropoffLocations: mapValues(
    defaultDropoffLocations || {},
    (dropoff) => camelCaseKeys(dropoff),
  ),
  shopifyConnectorId,
  shopifyIntegrationEnabled,
  notificationUsers: camelCaseKeys(notificationUsers || []),
  orderLookupMethods,
  collectionScheduleByCountry: Object.fromEntries(
    Object
      .entries(collectionScheduleByCountry || {})
      .map(([countryCode, props]) => [countryCode, camelCaseKeys(props)]),
  ),
});

export const toReturnRequestSetting = ({
  allowCustomComment,
  allowedDaysForReturn,
  allowPickupLocationSelection,
  allowPreDefinedComments,
  allowUndeliveredItems,
  defaultDropoffLocations = defaultReturnRequestSetting.defaultDropoffLocations,
  shopifyConnectorId = defaultReturnRequestSetting.shopifyConnectorId,
  shopifyIntegrationEnabled = defaultReturnRequestSetting.shopifyIntegrationEnabled,
  orderLookupMethods = defaultReturnRequestSetting.orderLookupMethods,
  notificationUsers = defaultReturnRequestSetting.notificationUsers,
  collectionScheduleByCountry,
}) => ({
  allow_custom_comment: allowCustomComment,
  allowed_days_for_return: allowedDaysForReturn,
  allow_pickup_location_selection: allowPickupLocationSelection,
  allow_pre_defined_comments: allowPreDefinedComments,
  allow_undelivered_items: allowUndeliveredItems,
  default_dropoff_locations: mapValues(
    defaultDropoffLocations || {},
    (dropoff) => snakeCaseKeys(dropoff),
  ),
  shopify_connector_id: shopifyConnectorId,
  shopify_integration_enabled: shopifyIntegrationEnabled,
  notification_users: snakeCaseKeys(notificationUsers),
  order_lookup_methods: orderLookupMethods,
  collection_schedule_by_country: Object.fromEntries(
    Object
      .entries(collectionScheduleByCountry || {})
      .map(([countryCode, props]) => [countryCode, snakeCaseKeys(props)]),
  ),
});
