import {
  filter,
  intersection,
  keyBy,
  sortBy,
  some,
  uniqBy,
  get,
} from 'lodash';

import {
  getStates,
  updateGlobal,
  updateCitiesByCountry,
  updateStatesByCountry,
  updateCitiesByState,
} from '../store';
import { reader, writer } from '../helpers/action';
// eslint-disable-next-line import/no-cycle
import { getSubscription } from './subscription';
import * as apiClient from '../api-client';
import { fromCity, fromState } from '../api-transforms';
import {
  alertCategories,
  countries as allCountries,
  gccCountryCodes,
  locales,
} from '../enums';
import * as reasonCodeActions from './reason-code';

import promiseCacher from '../helpers/promiseCacher';
import getErrorFromBEResponse from '../helpers/getErrorFromBEResponse';
import filterEnumListByMerchants from '../helpers/filterEnumListByMerchants';
import { fetchUserTenantPref, saveUserTenantPref } from '../helpers/userPrefLocalStorage';

import { error as toastError } from './toast';
import { getLocationOptions } from './locations-list';
import { getCarrierNameOptions, getCarrierOptions } from './carrier';
import { showLoadingState, hideLoadingState } from './loading';

export const setState = updateGlobal;

/**
 * @param {{merchants: string[]|null}[]} enumList
 */
function filterAccountLevelEnums(enumList) {
  return enumList.filter(({ merchants }) => !merchants);
}


export const getUserConfigs = async () => {
  showLoadingState();
  const alertSubscriptions = (await apiClient
    .getUserAlertSettings()
    .catch((err) => {
      if (err.response?.status === 404) {
        return;
      }
      throw err;
    })) || {};
  setState({
    // FIXME: rename this to userPreference
    userSettings: Object.fromEntries(
      alertCategories.map(
        ({ field }) => [field, alertSubscriptions[field] || false],
      ),
    ),
  });
  hideLoadingState();
};

export const getUserDefinedEnums = async ({
  props = [
    'tenantSettings',
    'carrierMetadata',
    'orderTypes',
    'deliveryTypes',
    'customAttributes',
    'merchants',
  ],
  bustCache = false,
} = {}) => {
  showLoadingState();

  const { data: subscription } = await getSubscription();

  if (subscription.active === false) {
    setState({ tenantSettings: subscription });
    hideLoadingState();
    return {};
  }

  // make sure getUserDefinedEnums() is the first call
  const blockingFetchesPromise = Promise.all([
    apiClient.getUserDefinedEnums({ props, bustCache }),
  ]);
  const nonBlockingFetchesPromise = Promise.all([
    getUserConfigs(),
    reasonCodeActions.list(),
    getLocationOptions({ allMerchants: true }),
    getCarrierOptions({ allMerchants: true }),
    getCarrierNameOptions({ allMerchants: true }),
  ]);
  const [userDefinedEnums] = await blockingFetchesPromise;

  const customAttributesSubscribed = get(
    subscription,
    ['customAttributesSubscribed'],
    false,
  );
  const {
    auth: {
      canAccessAllMerchants,
      countries: countryAccess = [],
    },
  } = getStates();

  const {
    carrierMetadata = [],
    tenantSettings,
    tenantSettings: {
      translationLanguages,
    } = {},

    // merchant dependent enums
    orderTypes: allOrderTypes,
    deliveryTypes: allDeliveryTypes,
    customAttributes,
  } = userDefinedEnums;

  // accessible merchants
  let { merchants } = userDefinedEnums;

  const allCustomAttributes = customAttributesSubscribed ? customAttributes : [];
  merchants = sortBy(merchants, [({
    name = '',
  }) => name.toLowerCase()]);

  const userAccessibleCountries = countryAccess.length
    ? filter(
      allCountries,
      ({ value }) => countryAccess.includes(value),
    ) : allCountries;

  const merchantIds = merchants.map(({ id }) => id);
  // handle case where user loses access to a merchant
  const savedMerchants = fetchUserTenantPref('global-selectedMerchantIds') || [];
  const selectedMerchantIds = intersection(
    savedMerchants.length > 0 ? savedMerchants : merchantIds,
    merchantIds,
  );
  const selectedMerchants = merchants.filter(({ id }) => selectedMerchantIds.includes(id));
  const allMerchantsSelected = merchants.length === selectedMerchantIds.length;

  // account level access flags
  const viewHasOnlyOneMerchant = merchants.length === 1;
  const firstMerchant = merchants?.[0];
  const canAccessAccountNotificationSettings = (
    canAccessAllMerchants
    && (!viewHasOnlyOneMerchant || !firstMerchant?.notificationSettingsOverrideEnabled)
  );
  // decides what to show on switchable pages
  const canShowAccountNotificationSettings = (
    allMerchantsSelected && canAccessAccountNotificationSettings
  );

  const orderTypesForMerchants = filterEnumListByMerchants(
    allOrderTypes,
    selectedMerchantIds,
  );
  const orderTypesForAccount = filterAccountLevelEnums(allOrderTypes);
  const deliveryTypesForMerchants = filterEnumListByMerchants(
    allDeliveryTypes,
    selectedMerchantIds,
  );
  const deliveryTypesForAccount = filterAccountLevelEnums(allDeliveryTypes);
  const customAttributesForMerchants = filterEnumListByMerchants(
    allCustomAttributes,
    selectedMerchantIds,
  );
  const customAttributesForAccount = filterAccountLevelEnums(allCustomAttributes);

  const emailNotificationEnabled = some(selectedMerchants, 'emailNotificationEnabled');
  const smsNotificationEnabled = some(selectedMerchants, 'smsNotificationEnabled');
  const whatsappNotificationEnabled = some(selectedMerchants, 'whatsappNotificationEnabled');
  const pinpointLocationEnabled = some(selectedMerchants, 'pinpointLocationEnabled');
  const feedbackEnabled = some(selectedMerchants, 'feedbackEnabled');
  const returnsEnabled = some(selectedMerchants, 'returnsEnabled');

  const selectedMerchantIdsWithReturnRequestEnabled = selectedMerchants
    .filter((merchant) => merchant.returnsEnabled)
    .map(({ id }) => id);
  const translationLanguageOptions = locales.filter((item) => translationLanguages.includes(item.value));
  const globalData = {
    globalEnumsLoaded: true,
    tenantSettings: {
      ...tenantSettings,
      translationLanguageOptions,
      ...subscription,
    },
    merchants,
    merchantIds,
    selectedMerchants,
    selectedMerchantIds,
    selectedMerchantIdsWithReturnRequestEnabled,
    allMerchantsSelected,

    // account level access flags
    canAccessAccountNotificationSettings,
    canShowAccountNotificationSettings,

    // each enum list is derived into three lists.
    // 1. enums relevant for merchants selected (including enums with "merchants" as null)
    // 2. enums that are purely account level (i.e. ones with "merchants" as null)
    // (#1 and #2 are not mutually exclusive)
    // 3. all of them (regardless of level)
    carrierMetadata,

    orderTypes: orderTypesForMerchants,
    orderTypesForAccount,
    allOrderTypes,

    deliveryTypes: deliveryTypesForMerchants,
    deliveryTypesForAccount,
    allDeliveryTypes,

    userAccessibleCountries,
    customAttributes: customAttributesForMerchants,
    customAttributesForAccount,
    allCustomAttributes,

    emailNotificationEnabled,
    smsNotificationEnabled,
    whatsappNotificationEnabled,
    feedbackEnabled,
    pinpointLocationEnabled,
    returnsEnabled,
  };
  setState(globalData);

  await nonBlockingFetchesPromise;

  hideLoadingState();
  return globalData;
};

export const selectMerchants = (selectedMerchantIds) => {
  // also re-calculate all merchant dependent enums
  const {
    global: {
      merchants,
      allOrderTypes,
      allDeliveryTypes,
      allCustomAttributes,
      canAccessAccountNotificationSettings,
    },
  } = getStates();
  const selectedMerchants = merchants.filter(({ id }) => selectedMerchantIds.includes(id));
  const allMerchantsSelected = merchants.length === selectedMerchantIds.length;
  saveUserTenantPref('global-selectedMerchantIds', allMerchantsSelected ? [] : selectedMerchantIds);
  const canShowAccountEnums = allMerchantsSelected;
  const canShowAccountNotificationSettings = (
    allMerchantsSelected && canAccessAccountNotificationSettings
  );

  // const carriersForMerchants = filterEnumListByMerchants(allCarriers, selectedMerchantIds);
  const orderTypesForMerchants = filterEnumListByMerchants(
    allOrderTypes,
    selectedMerchantIds,
  );
  const deliveryTypesForMerchants = filterEnumListByMerchants(
    allDeliveryTypes,
    selectedMerchantIds,
  );
  const customAttributesForMerchants = filterEnumListByMerchants(
    allCustomAttributes,
    selectedMerchantIds,
  );

  const emailNotificationEnabled = some(selectedMerchants, 'emailNotificationEnabled');
  const smsNotificationEnabled = some(selectedMerchants, 'smsNotificationEnabled');
  const whatsappNotificationEnabled = some(selectedMerchants, 'whatsappNotificationEnabled');
  const pinpointLocationEnabled = some(selectedMerchants, 'pinpointLocationEnabled');
  const feedbackEnabled = some(selectedMerchants, 'feedbackEnabled');
  const returnsEnabled = some(selectedMerchants, 'returnsEnabled');

  const selectedMerchantIdsWithReturnRequestEnabled = selectedMerchants
    .filter((merchant) => merchant.returnsEnabled)
    .map(({ id }) => id);

  setState({
    selectedMerchants,
    selectedMerchantIds,
    selectedMerchantIdsWithReturnRequestEnabled,
    allMerchantsSelected,
    canShowAccountEnums,
    canShowAccountNotificationSettings,

    orderTypes: orderTypesForMerchants,
    deliveryTypes: deliveryTypesForMerchants,
    customAttributes: customAttributesForMerchants,

    emailNotificationEnabled,
    smsNotificationEnabled,
    whatsappNotificationEnabled,
    feedbackEnabled,
    pinpointLocationEnabled,
    returnsEnabled,
  });
};

export const fetchAddressModel = async () => {
  try {
    const addressModels = (await apiClient.getAddressModels()) || [];
    const addressModelByCountry = keyBy(addressModels, 'country');
    updateGlobal({ addressModelByCountry });
    return addressModelByCountry;
  } catch (err) {
    const errorMessage = getErrorFromBEResponse(err);
    toastError(errorMessage);
  }
  return null;
};

export const setCitiesForCountry = (country, cities) => {
  updateCitiesByCountry({
    [country]: cities,
  });
};

export const setCitiesByState = (country, citiesByStateList) => {
  updateCitiesByState({
    [country]: citiesByStateList,
  });
  return citiesByStateList;
};

export const setStatesForCountry = (country, states) => {
  updateStatesByCountry({
    [country]: states,
  });
};

export const fetchCitiesForCountry = promiseCacher(
  async (country) => {
    let cityList = [];
    let stateList = [];
    let citiesPerState = [];

    try {
      const [cities, states] = await Promise.all([
        apiClient.getCityList({ country }),
        apiClient.getStateList({ country }),
      ]);
      cityList = sortBy(uniqBy(cities.map(fromCity), 'value'), 'label');
      stateList = sortBy(uniqBy(states.map(fromState), 'value'), 'label');
      citiesPerState = stateList.map(({ label, value }) => ({
        cities: sortBy(filter(cities, { state_code: value }).map(fromCity), 'label'),
        label,
        value,
      }));
      setCitiesForCountry(country, cityList);
      setCitiesByState(country, citiesPerState);
      setStatesForCountry(country, stateList);
    } catch (err) {
      console.error(err);
    }
    return {
      cityList,
      stateList,
      citiesPerState,
    };
  },
);

export const preloadGccCountries = () => {
  // pre-load GCC cities and states
  const { cities: citiesByCountry } = getStates();
  gccCountryCodes.forEach((country) => {
    if (!citiesByCountry[country]) fetchCitiesForCountry(country);
  });
};

export const isNavDrawerOpen = () => {
  let navDrawerOpen = null;
  try {
    navDrawerOpen = localStorage.getItem('navDrawerOpen');
  } catch (err) {
    // safari private mode
  }

  return navDrawerOpen === null || navDrawerOpen === 'true';
};

export const getNavDrawerOpen = () => setState({ navDrawerOpen: isNavDrawerOpen() });

export const setNavDrawerOpen = (navDrawerOpen) => {
  try {
    localStorage.setItem('navDrawerOpen', navDrawerOpen);
  } catch (err) {
    // safari private mode
  }

  setState({ navDrawerOpen });
};

export const notifyChange = async (payload) => {
  const notifyResult = (await apiClient.notifyChange(payload)) || [];
  return notifyResult;
};

export const getAppFeedbackState = reader(
  'application feedback state',
  async (...args) => {
    const { data } = await apiClient.getAppFeedbackState(...args);
    return data;
  },
);

export const postAppFeedbackInfo = writer(
  'application feedback',
  async (...args) => {
    const { data } = await apiClient.postAppFeedbackInfo(...args);
    return data;
  },
);
