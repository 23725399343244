import React from 'react';
import Dialog from 'lib-frontend-shared/src/components/Dialog';

export const makeProgressStepper = (steps, state) => {
  const gotoStep = (step) => state.update({
    step: typeof step === 'number' ? step : steps.findIndex(
      (entry) => entry.stepId === step,
    ),
  });
  return {
    goto: gotoStep,
    ...Object.fromEntries(
      Object.entries({ next: +1, prev: -1 }).map(([key, offset]) => [
        key, () => gotoStep(state.step + offset),
      ]),
    ),
  };
};

export const makeProgressDialog = ({ steps, title, ...rest }) => ({ update, ...state }) => {
  const onClose = async (event) => {
    update({ waiting: true });
    return state.onClose(event, state);
  };

  const {
    actions: getActions = () => [],
    Content,
    subtitle = () => null,
    onMinimize = () => null,
  } = steps[state.step];

  const props = {
    ...state,
    onClose,
    step: makeProgressStepper(steps, { ...state, update }),
    update,
  };

  const actions = getActions(props).map((action) => ({
    ...action,
    state: action.overrideWaiting || !state.waiting ? action.state : 'waiting',
  }));

  return (
    <Dialog
      {...rest}
      actions={actions}
      disableCloseButton={state.waiting}
      noPadding
      onClose={onClose}
      onMinimize={onMinimize(props)}
      open
      subtitle={subtitle(props)}
      title={typeof title === 'function' ? title(props) : title}
    >
      <Content {...props} />
    </Dialog>
  );
};
