import { capitalize, identity } from 'lodash';

import pastTense from './pastTense';

const isIgnorable = (error) => ['AbortError', 'CanceledError'].includes(error.name);

const makeActions = ({
  getErrorFromBEResponse,
  loadingActions: {
    showLoadingState,
    hideLoadingState,
  },
  logForbidden = true,
  toastActions,
}) => {
  const statusesToSkipBrowserLogsFor = [401, ...(logForbidden ? [403] : []), 500];

  /**
   * @template T
   * @param {string} entity
   * @param {(...any) => Promise<T>} func
   * @param {object} options
   * @param {string} [options.operation='load']
   * @param {((error: string) => any)|string} [options.onFailure='Unable to load <entity>']
   */
  const reader = (entity, func, options = {}) => async (...args) => {
    const {
      ignore = { result: undefined, status: [] },
      operation = 'load',
      onFailure = `Unable to ${operation} ${entity}`,
      onFinally = identity,
      noLoading = false,
    } = options;
    if (!noLoading) showLoadingState();
    try {
      const data = await func(...args);
      return { data };
    } catch (error) {
      if (!isIgnorable(error)) {
        const message = getErrorFromBEResponse(error);
        if (!statusesToSkipBrowserLogsFor.includes(error.response?.status)) {
          window.$logger?.error({
            message: `[API_ERROR] ${message}`,
            stack: error.stack,
          });
        }
        const { response: { status } = {} } = error;
        if (ignore.status.includes(status)) {
          const { result } = ignore;
          const data = typeof result === 'function'
            ? result(error)
            : result;
          return { data };
        }
        if (onFailure) {
          if (typeof onFailure === 'string') {
            toastActions.error([onFailure, message]);
          } else {
            await onFailure(message, error, ...args);
          }
        }
      }
      return { error };
    } finally {
      onFinally();
      if (!noLoading) hideLoadingState();
    }
  };

  /**
   * @template T
   * @param {string} entity
   * @param {(...any) => Promise<T>} func
   * @param {object} options
   * @param {string} [options.operation='load']
   * @param {((result: T, ...any) => any)|string} [options.onSuccess='Saved <entity> successfully.']
   * @param {((error: string) => any)|string} [options.onFailure='Unable to save <entity>"']
   */
  const writer = (entity, func, options = {}) => async (...args) => {
    const {
      operation = 'save',
      onSuccess = `${capitalize(pastTense(operation))} ${entity} successfully.`,
      onFailure = `Unable to ${operation} ${entity}`,
      onFinally = identity,
      noLoading = false,
    } = options;
    if (!noLoading) showLoadingState();
    try {
      const result = await func(...args);
      if (onSuccess) {
        if (typeof onSuccess === 'string') {
          toastActions.success(onSuccess);
        } else {
          await onSuccess(result, ...args);
        }
      }
      return { data: result };
    } catch (error) {
      if (!isIgnorable(error)) {
        const message = getErrorFromBEResponse(error);
        if (!statusesToSkipBrowserLogsFor.includes(error.response?.status)) {
          window.$logger.error({
            message: `[API_ERROR] ${message}`,
            stack: error.stack,
          });
        }
        if (onFailure) {
          if (typeof onFailure === 'string') {
            toastActions.error([onFailure, message]);
          } else {
            await onFailure(message, error);
          }
        }
      }
      return { error };
    } finally {
      onFinally();
      if (!noLoading) hideLoadingState();
    }
  };

  return { reader, writer };
};

export default makeActions;
