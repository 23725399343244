import React, {
  createRef, forwardRef, useEffect, useState,
} from 'react';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  parseDate, parseDateTime, today, getLocalTimeZone,
} from '@internationalized/date';
import {
  Today as TodayIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';
import cls from 'lib-frontend-shared/src/helpers/cls';
import Button from 'lib-frontend-shared/src/components/Button';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import Typography from 'lib-frontend-shared/src/components/Typography';
import useAsyncState from '../../helpers/useAsyncState';
import './DateTimePicker.scss';
import getTimezone from '../../helpers/getTimezone';

export const isPastDate = (date, timezone) => moment(date).tz(timezone || moment.tz.guess()).isBefore(moment().tz(timezone || moment.tz.guess()), 'minute');
export const isFutureDate = (date, timezone) => moment(date).tz(timezone || moment.tz.guess()).isAfter(moment().tz(timezone || moment.tz.guess()), 'minute');

const castSameTimeOfDayToTimeZone = (date, sourceTimezone, targetTimezone) => moment
  .tz(moment.tz(date, sourceTimezone).format('YYYY-MM-DD HH:mm'), targetTimezone)
  .toISOString();

const RenderDateTimePicker = ({
  key,
  disabled = false,
  disablePast = false,
  disableFuture = false,
  bypassFormValidation = false,
  isPastDate: extIsPastDate = isPastDate,
  isFutureDate: extIsFutureDate = isFutureDate,
  format,
  clearable = false,
  placement = 'bottom',
  showTimezoneInfo,
  timezone,
  userTimezone,
  label,
  errorMessage = 'Date should not be in past.',
  disableErrorPopup,
  orientation = 'vertical',
  onChange,
  onBlur,
  style = {},
  variant = 'compact',
  granularity,
  value,
  className,
  width = 'auto',
  dateTimeParser,
  showTime = false,
  ref,
  onlyShowTimezoneInfo,
  buffer = 0, // Add buffer time in minutes
}) => {
  const [sanitizedValue, setSanitizedValue] = useState(null);
  const [selectedDate, setSelectedDate] = useState({});
  const {
    formattedSelectedDateTime = null,
    rawSelectedDateTime = null,
  } = selectedDate;
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const getFormattedDate = ({
    dateTime,
    timezone: updatedTimezone,
    dateTimeParser: updatedDateTimeParse,
  }) => {
    // eslint-disable-next-line no-nested-ternary
    const update = dateTime && moment(new Date(dateTime)).isValid()
      ? (updatedTimezone ? castSameTimeOfDayToTimeZone(dateTime, updatedTimezone, userTimezone)
        : new Date(dateTime))
      : null;
    return {
      rawDateTime: update,
      formattedDateTime: update ? updatedDateTimeParse(moment(update).format(format)) : null,
    };
  };
  const constructDateTime = ({
    dateTime, timezone: updatedTimezone, setCurrentTime = false,
  }) => {
    if (!dateTime) {
      return null;
    }
    const serializedDate = new Date(dateTime);
    let rawDateFormat = null;
    if (updatedTimezone) {
      const updatedDate = moment(new Date(dateTime)).isValid()
        ? moment(serializedDate)
        : null;
      rawDateFormat = updatedDate && updatedDate.isValid() ? moment(castSameTimeOfDayToTimeZone(updatedDate, userTimezone, updatedTimezone)) : null;
    } else {
      rawDateFormat = moment(serializedDate).isValid() ? moment(serializedDate) : null;
    }
    if (setCurrentTime) {
      if (updatedTimezone) {
        const timeZonedDate = moment.tz(moment().toISOString(), updatedTimezone);
        rawDateFormat.add(timeZonedDate.hour(), 'hours');
        rawDateFormat.add(timeZonedDate.minutes() + buffer, 'minutes');
      } else {
        const currentDateTime = moment();
        rawDateFormat.set({ hours: currentDateTime.hour(), minutes: currentDateTime.minutes() });
      }
    }
    return rawDateFormat;
  };
  const inputEl = ref?.current?.querySelector('input[type="hidden"]');
  if (inputEl) {
    inputEl.type = 'text';
  }
  useEffect(() => {
    if (!isPickerOpen) {
      if (!value) {
        setSanitizedValue(null);
        return;
      }
      const {
        rawDateTime,
        formattedDateTime,
      } = getFormattedDate({
        dateTime: value,
        timezone,
        dateTimeParser,
      });
      const isSame = moment(rawDateTime).isSame(sanitizedValue);
      if (!isSame || timezone) {
        setSanitizedValue(formattedDateTime);
        setSelectedDate({
          rawSelectedDateTime: rawDateTime,
          formattedSelectedDateTime: formattedDateTime,
        });
      }
    }
  }, [value, timezone]);

  const [modules] = useAsyncState(async () => {
    const {
      DatePicker: DatePickerComponent,
      DateInput,
      Popover,
      Dialog,
      Button: AriaButton,
      DateSegment,
      Calendar,
      Heading,
      Group,
      TimeField,
      CalendarGrid,
      CalendarCell,
    // eslint-disable-next-line import/no-extraneous-dependencies
    } = await import('react-aria-components');
    return {
      DatePickerComponent,
      DateInput,
      AriaButton,
      Popover,
      Group,
      Dialog,
      DateSegment,
      Calendar,
      Heading,
      CalendarGrid,
      TimeField,
      CalendarCell,
    };
  }, null);

  if (!modules) return null;

  const {
    DatePickerComponent,
    DateInput,
    Popover,
    AriaButton,
    Group,
    Dialog,
    DateSegment,
    Calendar,
    Heading,
    CalendarGrid,
    CalendarCell,
    TimeField,
  } = modules;

  const triggerRef = createRef();
  const isSelectedDateInPast = (!disablePast || disableErrorPopup || disabled) ? false : extIsPastDate(value);
  const isSelectedDateInFuture = (!disableFuture || disableErrorPopup || disabled) ? false : extIsFutureDate(value);

  const onClose = () => {
    setIsPickerOpen(false);
    setSelectedDate({
      rawSelectedDateTime: value,
      formattedSelectedDateTime: getFormattedDate({ dateTime: value, timezone, dateTimeParser }).formattedDateTime,
    });
  };
  return (
    <Linear
      align="center"
      width={width}
      key={key}
    >
      <Linear
        width="100Pr"
        gap="xs"
        orientation={orientation}
      >
        {label && <Typography variant="para.xs" color="description">{label}</Typography>}
        <Linear orientation="vertical" width="100Pr">
          <DatePickerComponent
            className={cls('DateRangePicker', { variant }, className)}
            hideTimeZone
            style={style}
            hourCycle={12}
            granularity={granularity}
            minValue={disablePast && !bypassFormValidation ? today(getLocalTimeZone()) : null}
            maxValue={disableFuture && !bypassFormValidation ? today(getLocalTimeZone()) : null}
            isDisabled={disabled}
            onBlur={onBlur}
            onChange={(date) => {
              const rawDateTime = constructDateTime({
                dateTime: date, timezone, setCurrentTime: !sanitizedValue,
              });
              if (isPickerOpen) {
                setSelectedDate({
                  rawSelectedDateTime: rawDateTime,
                  formattedSelectedDateTime: rawDateTime ? getFormattedDate({ dateTime: rawDateTime, timezone, dateTimeParser }).formattedDateTime : null,
                });
                return;
              }
              onChange(rawDateTime);
            }}
            value={sanitizedValue}
            ref={ref}
          >
            <Group ref={triggerRef} className="DateTimePicker-group">
              <DateInput className="DateTimePicker-dateInput">
                {(segment) => <DateSegment className={cls('DateTimePicker-dateSegment', { dateInPast: isSelectedDateInPast || isSelectedDateInFuture })} segment={segment} />}
              </DateInput>
              <Linear align="center" className={cls('DateTimePicker-actionButtonWrapper', { hasClearAction: clearable && Boolean(sanitizedValue) })}>
                {clearable && Boolean(sanitizedValue) && (
                <ClearIcon
                  disabled={disabled}
                  className="DateTimePicker-actionClear"
                  size="small"
                  onClick={() => onChange(null)}
                />
                )}
                <AriaButton
                  onClick={() => setIsPickerOpen(!isPickerOpen)}
                  className="DateTimePicker-actionCalendar"
                  isDisabled={disabled}
                >
                  <TodayIcon fontSize="small" />
                </AriaButton>
              </Linear>
              <Popover
                disableEnforceFocus
                isOpen={isPickerOpen}
                className="DateTimePicker-popover"
                placement={placement}
                onOpenChange={(action) => (!action ? onClose() : null)}
                triggerRef={triggerRef}
              >
                <Dialog>
                  <Calendar
                    value={formattedSelectedDateTime}
                    className="DateTimePicker-calendar"
                  >
                    <Linear align="center" justify="space" className="DateTimePicker-calendarHeader">
                      <AriaButton className="DateTimePicker-calendarHeaderButton" slot="previous"><KeyboardArrowLeftIcon fontSize="large" /></AriaButton>
                      <Heading className="DateTimePicker-calendarHeading" />
                      <AriaButton className="DateTimePicker-calendarHeaderButton" slot="next"><KeyboardArrowRightIcon fontSize="large" /></AriaButton>
                    </Linear>
                    <CalendarGrid focusedValue={formattedSelectedDateTime}>
                      {(date) => <CalendarCell date={date} />}
                    </CalendarGrid>
                    {showTime && (
                    <TimeField
                      className="DateTimePicker-timeField"
                      hourCycle={12}
                      value={formattedSelectedDateTime}
                      hideTimeZone
                      onChange={(time) => {
                        const rawDateTime = constructDateTime({
                          dateTime: time,
                          timezone,
                        });
                        setSelectedDate({
                          rawSelectedDateTime: moment(timezone ? moment(rawDateTime).format(format) : rawDateTime),
                          formattedSelectedDateTime: getFormattedDate({ dateTime: rawDateTime, timezone, dateTimeParser }).formattedDateTime,
                        });
                      }}
                    >
                      <Spacer y="sm" />
                      <DateInput className="DateTimePicker-timeInput">
                        {(segment) => <DateSegment className="DateTimePicker-dateSegment" segment={segment} />}
                      </DateInput>
                    </TimeField>
                    )}
                  </Calendar>
                  <Spacer y="sm" />
                  <Linear align="center" justify="end" gap="md">
                    <Button onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      color="normal"
                      disabled={!rawSelectedDateTime || !moment(rawSelectedDateTime).isValid()}
                      onClick={() => {
                        setIsPickerOpen(false);
                        onChange(moment(rawSelectedDateTime), 'picker');
                      }}
                    >
                      Ok
                    </Button>
                  </Linear>
                </Dialog>
              </Popover>
            </Group>
          </DatePickerComponent>
          {(onlyShowTimezoneInfo || (showTimezoneInfo && timezone)) && (
          <Linear orientation="vertical" width="100Pr" align="end">
            <Typography variant="para.xs:body">
              {`${onlyShowTimezoneInfo || timezone} Timezone (UTC ${moment().tz(onlyShowTimezoneInfo || timezone).format('Z')})`}
            </Typography>
          </Linear>
          )}
        </Linear>
        {isSelectedDateInPast && errorMessage && (
          <Typography variant="para.xs" color="error">{errorMessage}</Typography>
        )}
      </Linear>
    </Linear>
  );
};

export const DatePicker = forwardRef((props, ref) => RenderDateTimePicker({
  ...props,
  ref,
  format: 'YYYY-MM-DD',
  granularity: 'day',
  style: { width: '200px', ...props.style },
  dateTimeParser: parseDate,
}));

export const DateTimePicker = forwardRef((props, ref) => {
  const timezoneOrCountry = props?.timezoneOrCountry;
  const timezone = timezoneOrCountry ? getTimezone(timezoneOrCountry) : null;
  return RenderDateTimePicker({
    ...props,
    ref,
    format: 'YYYY-MM-DDTHH:mm',
    dateTimeParser: parseDateTime,
    timezone,
    userTimezone: timezone ? moment.tz.guess() : null,
    granularity: 'minute',
    style: { width: '260px', ...props.style },
    showTime: true,
  });
});

export default RenderDateTimePicker;
