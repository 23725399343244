import { identity } from 'lodash';

import { getStates, updateBackground, useGlobalStates } from '../store';

/**
* @typedef {Object} Activity
* @property {string} heading
* @property {React.FunctionComponent} Content
* @property {React.FunctionComponent} Summary
* @property {function} onClose
* @property {boolean} hasSeen // internally managed
* @property {boolean} notable
* @property {boolean} visible
* @property {boolean} waiting
* @property {...Object} props
*/

const patchState = (getUpdate) => {
  const { background } = getStates();
  return updateBackground(getUpdate(background));
};

const DefaultSummary = (state) => JSON.stringify(state);

/**
 * @params {Activity} activity
 */
const createActivity = ({
  heading = '',
  Content = null,
  Summary = DefaultSummary,
  onClose = identity,

  hasSeen = false,
  notable = true,
  visible = false,
  waiting = false,
  ...rest
}) => {
  const activityWrap = {
    ...rest,
    heading,
    Content,
    Summary,
    onClose,

    hasSeen,
    notable,
    visible,
    waiting,
  };
  patchState(({ activities }) => ({ activities: [...activities, activityWrap] }));
  return activityWrap;
};

/**
 * @params {Activity} activity
 */
const deleteActivity = (activity) => patchState(
  ({ activities }) => ({ activities: activities.filter((val) => val !== activity) }),
);

/**
 * @params {Activity} activity
 * @params {Activity} update
 */
const updateActivity = (activity, changes) => patchState(
  ({ activities }) => ({
    activities: activities.map((val) => {
      if (val !== activity) return val;
      const update = typeof changes === 'function'
        ? changes(val)
        : changes;
      return Object.assign(val, {
        ...update,
        // transition from waiting to non-waiting triggers !hasSeen
        ...(Object.hasOwn(update, 'waiting') ? {
          hasSeen: val.waiting && !update.waiting ? false : update.hasSeen,
        } : {}),
      });
    }),
  }),
);

const toggleView = () => patchState(({ activities, isManaging }) => ({
  activities: activities.map((activity) => Object.assign(
    activity,
    { hasSeen: isManaging ? true : activity.hasSeen },
  )),
  isManaging: !isManaging,
}));

const useBackground = () => {
  const { background } = useGlobalStates(['background']);
  return {
    createActivity,
    deleteActivity,
    updateActivity,

    activities: background.activities,
    isManaging: background.isManaging,
    patchState,
    toggleView,
  };
};

export default useBackground;
