import { map, orderBy } from 'lodash';
import chain from 'lib-frontend-shared/src/helpers/chain';
import moment from '../helpers/moment';
import { carriyoClient } from './base';

/* eslint-disable-next-line import/prefer-default-export */
export const getHistoryChangeLogs = async ({
  entityType,
  id,
  // FIXME: remove this once we have a new API endpoint for changelog
  url,
}) => {
  const { data = [] } = await carriyoClient.get(url || `/changelogs/${entityType}${id ? `/${id}` : ''}`);
  return {
    data: chain(data)
      .fn(map, ({
        log_date: logDate,
        action,
        request_type: requestType,
        entity,
        record,
        source_id: sourceId,
        source_type: sourceType,
        system_log_identifier: systemLogIdentifier,
      }) => {
        const event = action || requestType;
        const changelogInfo = entity || record;
        const {
          updateDate,
          updatedByUser: author,
          ...state
        } = JSON.parse(changelogInfo);
        return {
          author,
          event,
          systemLogIdentifier,
          sourceId,
          sourceType,
          state,
          timestamp: logDate || updateDate,
        };
      })
      .fn(orderBy, [({ timestamp }) => moment(timestamp).valueOf()], ['desc'])
      .value,
  };
};
