import React, { useMemo } from 'react';
import { identity, some } from 'lodash';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import Drawer from 'lib-frontend-shared/src/components/Drawer';

import './MobileSelect.scss';

const MobileSelect = ({
  open = false,
  legend = 'Choose',
  name,
  options = [],
  value,
  onChange = identity,
  onClose = identity,
}) => {
  const isValidValue = useMemo(() => some(options, { value }), [options, value]);
  return (
    <Drawer
      side="bottom"
      onClose={onClose}
      open={open}
      size={isValidValue ? '50pr' : '100pr'}
    >
      <div className="MobileSelect-container">
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            {legend}
          </FormLabel>

          <RadioGroup
            name={name}
            value={value}
            onChange={onChange}
          >
            {options.map(({ value: optionValue, label: optionLabel }, index) => (
              <FormControlLabel
                key={optionValue}
                value={optionValue}
                control={(
                  <Radio
                    autoFocus={
                      isValidValue
                        ? (optionValue === value)
                        : (index === 0)
                    }
                  />
                )}
                label={optionLabel}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </Drawer>
  );
};
export default MobileSelect;
