import pluralize from 'lib-frontend-shared/src/helpers/pluralize';

import { getStates, updateAsyncTasks as rawUpdateAsyncTasks } from '../store';

import * as apiClient from '../api-client';

import { reader, writer } from '../helpers/action';

import { asyncTaskTerminalStatuses } from '../enums';

const singularEntity = 'asynchronous task';
const pluralEntity = pluralize(singularEntity);

export const getAsyncTask = reader(
  singularEntity,
  apiClient.getAsyncTask,
  { noLoading: true },
);

export const listAsyncTasks = reader(
  pluralEntity,
  apiClient.listAsyncTasks,
);

export const abortAsyncTask = writer(
  singularEntity,
  apiClient.abortAsyncTask,
  {
    onSuccess: null,
    operation: 'abort',
  },
);

export const dismissAsyncTask = writer(
  singularEntity,
  apiClient.dismissAsyncTask,
  {
    onSuccess: null,
    operation: 'dismiss',
  },
);

export const updateAsyncTasks = (asyncTasks, preserve) => {
  const { asyncTasks: { all: current } } = getStates();
  const newIds = asyncTasks.map(({ taskId }) => taskId);
  const all = [
    ...(preserve ? current.filter(({ taskId }) => !newIds.includes(taskId)) : []),
    ...asyncTasks,
  ];
  rawUpdateAsyncTasks({
    all,
    pending: all.filter(({ status }) => !asyncTaskTerminalStatuses.includes(status)),
  });
  return all;
};

export const fetchAsyncTasks = async (...args) => {
  const { data } = await listAsyncTasks(...args);
  updateAsyncTasks(data);
  return data;
};
